.chooseVerifContainer {
  background-color: #fff;
  max-width: 35em;
  /* width: 35em; */
 /* height: fit-content; */
    height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .chooseVerifContainer {
      max-width: 30em;
      background-color: #fff;
      width: 1000px;
      height: 1000px;
      padding-top: 40%;
    }
  } */

/* @media (max-width: 480px) {
    .chooseVerifContainer {
      max-width: 25em;
      background-color: #fff;
      width: 1000px;
      height: 1000px;
      padding-top: 40%;
    }
  } */

.chooseVerifLogo {
  width: 200px;
  height: 200px;
}

.chooseVerifJudul {
  font-size: 32px;
  font-weight: bold;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
}

.chooseVerifIconUser {
  height: 20px;
  padding-left: 20px;
}

.chooseVerifDescription {
  margin-top: 20px;
  width: 60%;
  font-family: "Lato-Bold", sans-serif;
  text-align: center;
}

.chooseVerifButton {
  margin-top: 20px;
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.chooseVerifButton .chooseVerifButtonSMS {
  margin-right: 20px;
  height: 40px;
  width: 45%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.chooseVerifButton .chooseVerifButtonWA {
  margin-left: 20px;
  height: 40px;
  width: 45%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.forgetPasswordTextButtonOK {
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
}

.chooseVerifContainerInput {
  margin-top: 15px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 3px 3px 3px #aaaaaa;
  border-color: #e8e8e8;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  padding-top: 7px;
}
