.topSpenderContainer {
  width: 100%;
  flex:1;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  font-family: "Lato-Bold", sans-serif;
}
.topSpenderContent {
  padding-top: 75px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}
.topSpenderImage {
  height: 180px;
  width: 86%;
  border-radius: 10px;
}
.judulTopSpender {
  padding-top: 3%;
  font-size: 14;
  width: 100%;
  text-align: center;
}
.tableTopSpender {
  padding-top: 5%;
  width: 80%;
}
table {
  width: 100%;
  height: fit-content;
}

th {
  padding: 2%;
  background-color:#62b852 ;
  color: #FFFFFF;
}

td {
  padding: 2%;
  text-align: center;
}
.colGanjil {
    background-color: #E8E8E8;
    color: #7D7D7D;
}
.colGenap {
    background-color: #C4C4C4;
    color: #7D7D7D;
}
.cardDescription {
    width: 80%;
    margin-top: 5%;
    min-height: 20%;
    border-radius: 10px;
    border-color: #C4C4C4;
    border-style: solid;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
}
.description {
    margin-top: 2%;
    color: #7d7d7d;
}
.btnTopSpender {
    background-color: #529F45;
    width: 80%;
    text-align: center;
    padding: 3%;
    border-style: solid;
    border-width: 0;
    margin-top: 5%;
    color: #fff;
    border-radius: 10px;
}