.headerComponent {
    flex: 1;
    width: 100%;
    height: 70px;
    max-width: 35em;
    /* position: sticky; */
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 5px solid #bcbc;
    z-index: 1090;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
}

.header {
    flex: 1;
    width: 100%;
    height: 70px;
    max-width: 35em;
    /* position: sticky; */
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 5px solid #bcbc;
    z-index: 1090;
    padding-left: 20px;
    /* padding-right: 20px; */
}

.headerReview {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
   height: fit-content;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    z-index: 1090;
}

.headerContainerLogo {
    /* background-color: aquamarine; */
    flex: 1;
}

.headerContainerLogo .headerLogo {
    width: 60px;
    height: 60px;
}

.headerSearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    /* width: 45%; */
    height: 70%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    flex: 1;
}

.headerSearchLogo {
    width: 20px;
    height: 20px;
}

.headerTextInput {
    border: 0;  
}

input:focus{
    outline: none;
}

.header .headerContainerMenu {
    display: flex;
    justify-content: space-evenly;
    /* width: 30%; */
    margin: 0px 15px;
    gap: 10px;
}

.headerDistributor {
    flex: 1;
    width: 100%;
    height: 70px;
    max-width: 35em;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    border-bottom: 5px solid #bcbc;
    z-index: 1090;
    padding-left: 20px;
    justify-content: space-between;
}

.headerDistributor .headerContainerMenu {
    display: flex;
    /* background-color: aqua; */
    justify-content: space-evenly;
    /* width: 30%; */
    margin: 0px 15px;
    gap: 10px;
}

/* .header .headerContainerIcon {
    width: 9%;
} */

.headerComponent .headerContainerMenu {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
    margin: 0px 14px;
    gap: 20px;
}

.headerFirstContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.headerSecondContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 2px solid #bcbc;
}

.headerSecondMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
   height: fit-content;
    padding: 10px 0px 20px 0px;
    border-style: solid;
    border-width: 0px;
    background-color: #fff;
}

.headerContainerMenu .headerMenuLonceng {
    max-width: 30px;
    max-height: 30px;
}

.headerContainerMenu .headerMenuKeranjang {
    max-width: 30px;
    max-height: 30px;
}

.headerContainerMenu .headerMenuLogo {
    max-width: 30px;
    max-height: 30px;
}
.divTitle {
    flex: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.headerArrowContainer {
    align-items: center;
}
.headerTitleContainer{
    margin-left: 3%;
    flex: 1;
    /* background-color: blanchedalmond; */
    font-size: 20px;
    font-family: sans-serif;
    color: rgb(30, 30, 30);
    width: auto;
}
