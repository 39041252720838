.transactionDetailContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
  /* height: fit-content; */
  min-height: 100vh;
  padding-top: 60px;
  /* min-height: 1200px; */
}
.transactionDetailHeader {
  flex: 1;
  width: 100%;
  /* height: fit-content; */
  height: 100vh;
  max-width: 35em;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  align-items: center;
  z-index: 1500;
}

.transactionDetailHeaderTitle {
  display: flex;
  align-items: center;
  padding-left: 4%;
  padding-top: 3%;
  padding-bottom: 3%;
  border-bottom: 5px solid #d4d4d4;
}

.transactionDetailBack {
  width: 13px;
  height: 13px;
  margin-right: 13px;
}

.transactionDetailBackText {
  margin-left: 10px;
  font-weight: bold;
  font-size: 17px;
}
.value2 {
  color: #529f45;
  max-width: 60%;
}
.transactionDetailCard {
  -webkit-box-shadow: 0 10px 5px #d4d4d4;
  -moz-box-shadow: 0 10px 5px #d4d4d4;
  box-shadow: 0 10px 5px #d4d4d4;
  /* height: fit-content; */
  /* height: 100vh; */
  margin-bottom: 2%;
  padding: 3%;
  font-family: "Lato-Bold", sans-serif;
}

.transactionDetailCard .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2%;
  padding-top: 2%;
  font-family: "Lato-Regular", sans-serif;
  overflow: hidden;
}

.transactionDetailCard .flex .title {
  color: #888888;
  font-family: "Lato-Bold", sans-serif;
}
.value {
  max-width: 73%;
}
.transactionDetailCard .flex .value .value2{
  text-align: right;
  font-family: "Lato-Bold", sans-serif;
  width: 40%;
}

.transactionDetailCard .flex .total {
  font-size: 20px;
  font-family: "Lato-Bold", sans-serif;
}

.transactionDetailCard .flex .totalPrice {
  font-size: 20px;
  color: #529f45;
  font-family: "Lato-Black", sans-serif;
}

._transactionDetailCardLast {
  height: fit-content;
  /* height: 100vh; */
  /* margin-bottom: 10%; */
  padding: 3%;
  padding-bottom: 10vh;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLast .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2%;
  padding-top: 2%;
}

._transactionDetailCardLast .flex .title {
  color: #888888;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLast .flex .value .value2{
  width: 40%;
  text-align: right;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLast .flex .total {
  font-size: 20px;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLast .flex .totalPrice {
  font-size: 20px;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLastRedeem {
  height: fit-content;
  /* height: 100vh; */
  /* margin-bottom: 10%; */
  padding: 3%;
  /* padding-bottom: 10vh; */
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLastRedeem .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2%;
  padding-top: 2%;
}

._transactionDetailCardLastRedeem .flex .title {
  color: #888888;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLastRedeem .flex .value .value2{
  width: 40%;
  text-align: right;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLastRedeem .flex .total {
  font-size: 20px;
  font-family: "Lato-Bold", sans-serif;
}

._transactionDetailCardLastRedeem .flex .totalPrice {
  font-size: 20px;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
}

.transactionDetailCard .productList {
  border-radius: 6px;
  border-width: 2px;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 15px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.main2 {
  margin-top: 5%;
}
.transactionDetailCard .productList .main {
  display: flex;
}

.transactionDetailCard .productList .main .content {
  margin-left: 2%;
}

.transactionDetailCard .productList .main .content .price {
  margin-top: 5px;
  color: #888888;
  font-family: "Lato-Bold", sans-serif;
}

.transactionDetailCard .productList .main .content .priceHalf {
  margin-top: 5px;
  font-family: "Lato-Bold", sans-serif;
  margin-left: 5px;
  color: #17a2b8;
}

.transactionDetailCard .productList .main img {
  height: 95px;
  width: 95px;
}

.transactionDetailCard .productList .footer {
  padding: 3px;
}

.transactionDetailCard .productList .footer .two {
  width: 60%;
  color: #000000;
  font-family: "Lato-Bold", sans-serif;
}
.two {
  text-align: start;
}
.transactionDetailFooter {
  flex: 1;
  width: 100%;
  max-width: 35em;
  position: fixed;
  padding: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
}

/* @media (max-width: 780px) {
    .transactionDetailFooter {
        flex: 1;
        width: 100%;
        max-width: 30em;
        position: fixed;
        padding: 10px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        text-align: center;
        align-items: center;
    }
} */

/* @media (max-width: 480px) {
    .transactionDetailFooter {
        flex: 1;
        width: 100%;
        max-width: 25em;
        position: fixed;
        padding: 10px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        text-align: center;
        align-items: center;
    }
} */

.transactionDetailFooter .checkStatusButton {
  height: 40px;
  width: 100%;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #529f45;
  border-radius: 10px;
}

.transactionDetailFooter .checkStatusText {
  text-align: center;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
}

.buttonSelesai {
  height: 40px;
  width: 100%;
  background-color: #529f45;
  /* border-style: solid;
    border-width: 1px;
    border-color: #529F45; */
  border-radius: 10px;
  border-color: #529f45;
}

.textSelesai {
  text-align: center;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
}

.buttonBatal {
  height: 40px;
  width: 100%;
  background-color: #e02626;
  border-style: solid;
  border: 1px;
  /* border-width: 1px;
    border-color: #529F45; */
  border-radius: 10px;
}

.textBatal {
  text-align: center;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
}

.buttonDisabled {
  height: 40px;
  width: 100%;
  background-color: #e07126;
  border-style: solid;
  /* border-width: 1px;
    border-color: #529F45; */
  border-radius: 10px;
}

.textDisabled {
  text-align: center;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
}

.name {
  font-family: 'Lato-Bold', sans-serif;
}
.textStock {
  margin-top: 2%;
  margin-bottom: 2%;
  color: #888888;
  font-family: 'Lato-Bold', sans-serif;
}
.btnTracking {
  display: flex;
  /* background-color: #e02626; */
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}
.textBtnTracking {
  background-color: #529f45;
  width: 80%;
  text-align: center;
  color: #ffffff;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  border-radius: 5px;
  cursor: default;
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
  border: none;
}