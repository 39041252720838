.container {
  background-color: #fff;
  max-width: 35em;
  padding-top: 40%;
  /* width: 35em; */
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* @media (max-width: 780px) {
  .container {
    max-width: 30em;
    background-color: #fff;
    width: 1000px;
    height: 1000px;
    padding-top: 40%;
  } 
} */

/* @media (max-width: 480px) {
  .container {
    max-width: 25em;
    background-color: #fff;
    width: 1000px;
    height: 1000px;
    padding-top: 40%;
  }
} */

.logo {
  width: 200px;
  height: 200px;
}

.judul {
  font-size: 32px;
  font-weight: bold;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
}

.IconUser {
  height: 20px;
  padding-left: 20px;
}

.containerInputLogin {
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 3px 3px 3px #aaaaaa;
  border-color: #e8e8e8;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  padding-top: 7px;
}

.textInputLogin {
  border-color: #bcbc;
  border-style: solid;
  border: 0;
  font-family: "Lato-Bold", sans-serif;
  font-size: 16px;
  width: 70%;
  margin-left: 45px;
}

textarea:focus,
input:focus {
  outline: none;
}

.buttonOK {
  margin-top: 30px;
  height: 40px;
  width: 60%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.textButtonOK {
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
}
