.notifListContainer {
    max-width: 35em;
    /* width: 35em; */
   /* height: fit-content; */
    min-height: 100vh;
    /* min-height: 1200px; */
    background-color: #fff;
    padding-top: 70px;
}

.notifListMain {
    /* padding-top: 260px; */
    padding-left: 5%;
    padding-right: 5%;
}

.notifListCard {
    margin-top: 15px;
    border-radius: 10px;
    border-width: 1px;
    background-color: #E9ECEB;
    border-color: #D3D3D3;
    border-style: solid;
    position: relative;
   /* height: fit-content; */
    /* height: 100vh; */
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .notifListCardWhite {
    margin-top: 15px;
    border-radius: 10px;
    border-width: 1px;
    background-color: #fff;
    border-color: #D3D3D3;
    border-style: solid;
    position: relative;
   /* height: fit-content; */
    /* height: 100vh; */
    padding: 8px;
  }

.notifListHeader {
    display: flex;
    align-items: center;
    font-size:smaller;
    font-family: 'Lato-Bold', sans-serif;
}

.notifListContent {
    text-align: left;
    margin-top: 7px;
    font-size:small;
    margin-bottom: 10px;
}

.notifListContentHeader {
    margin-top: 10px;
    font-family: 'Lato-Bold', sans-serif;
}

.notifListContentMain {
    margin-top: 5px;
    font-family: 'Lato-Bold', sans-serif;
}

.notifListIcon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
} 

.notifListRight {
    margin-left: auto;
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.notifListHeader .invoice {
    font-family: 'Lato-Bold', sans-serif;
}
