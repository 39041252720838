.userHeader {
    display: flex;
    height: 100px;
    margin-top: 100px;
    margin-bottom: 10%;
    align-items: center;
    justify-content: center;
    margin-left: 4%;
    margin-right: 4%;
}

.userLogo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.userList {
    margin-left: 4%;
    margin-right: 4%;
    font-family: 'Lato-Bold', sans-serif;
}

.userContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    padding-top: 8%;
    /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .userContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
        padding-top: 8%;
    }
} */

/* @media (max-width: 480px) {
    .userContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
        padding-top: 8%;
    }
} */


hr{
    height: 1px;
    background-color: #ccc;
    border: none;
}

.subUser {
    text-decoration: inherit;
    /* margin-top: 25px; */
    margin-bottom: 8px;
    display:flex;/*CSS3*/
    align-items:center;/*Vertical align*/
}

.userButtonBack {
    display: flex;
    align-items:center;/*Vertical align*/
}

.userBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.userBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.userTextTitle {
    margin-top: 15px;
    font-size: 15px;
    font-weight: bold;
    color: black;
}

.userText {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 200;
    color: black;
}

.userLinkEdit {
    text-decoration: inherit;
    color: green;
    margin-left: auto;
}

.editIcon {
    width: 45px;
    height: 45px;
    position: relative;
    right: 60px;
}