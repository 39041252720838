.addRatingContainer {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  min-height: 100vh;
  /* min-height: 1200px; */
  background-color: #fff;
  padding-top: 75px;
}

.addRatingContent {
  max-width: 35em;
  /* width: 35em; */
  height: -moz-fit-content;
  /* height: fit-content; */
  /* height: 100vh; */
  background-color: #fff;
  padding-right: 10px;
  padding-left: 10px;
}

.addRatingTopContainer {
  display: flex;
  flex-direction: row;
  width: 95%;
  max-width: 35em;
  /* height: fit-content; */
  /* height: 100vh; */
  margin: 10px 2%;
  padding: 15px 2%;
  border-style: solid;
  border-width: 1px;
  border-color: #bcbc;
  border-radius: 10px;
}

.addRatingTopLeftContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
}

.addRatingTopRightContainer {
  width: 70%;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.addRatingTopLeftImage {
  margin: 0px 5%;
}

.addRatingTopRightTitle {
  margin-top: 30px;
  font-family: "Lato-Bold", sans-serif;
  font-size: 17pt;
  font-weight: 600;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.addRatingTopRightText {
  margin-top: 10px;
  font-family: "Lato-Bold", sans-serif;
  font-size: 15pt;
  font-weight: 500;
  color: grey;
}

.addRatingTopRightTextHalf {
  margin-top: 10px;
  font-family: "Lato-Bold", sans-serif;
  font-size: 15pt;
  font-weight: 500;
  margin-left: 5px;
  color: #17a2b8;
}

.addRatingTopRightPrice {
  margin-top: 10px;
  font-family: "Lato-Bold", sans-serif;
  color: #529f45;
  font-size: 18pt;
}

.addRatingBottomContainer {
  width: 95%;
  max-width: 35em;
  /* height: fit-content; */
  /* height: 100vh; */
  margin: 10px 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addRatingTextAreaContainer {
  width: 100%;
  margin-top: 10px;
  padding: 10px 2%;
  border-style: solid;
  border-width: 1px;
  border-color: #bcbc;
  border-radius: 5px;
  height: 200px;
}

.addRatingTextArea {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 5px;
  height: fit-content;
  font-family: "Lato-Bold", sans-serif;
  color: black;
  font-size: 15pt;
}

.addRatingBottomButtonContainer {
  margin-top: 20px;
  width: 100%;
  max-width: 35em;
  height: 70px;
  padding-right: 25px;
  padding-left: 20px;
  /* padding-bottom: 11px; */
  align-items: center;
  display: flex;
}

.addRatingBottomButton {
  background-color: #529f45;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 70%;
  padding: 10px;
  border-style: solid;
  border-width: 0px;
  border-radius: 9px;
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
  color: #fff;
  font-size: 14pt;
  margin-bottom: 30px;
}
