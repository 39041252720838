.containerDetailComplaint {
  flex: 1;
  display: flex;
  background-color: #fff;
  max-width: 35em;
  min-height: 100vh;
  padding-bottom: 1%;
  flex-direction: column;
}
.headerDetailComplaint {
  display: flex;
}
.contentDetailComplaint {
  display: flex;
  flex: 1;
  /* margin-top: 13%; */
  margin-top: 18%;
  padding-left: 4%;
  padding-right: 4%;
  flex-direction: column;
  align-items: center;
}
.contentDateDetail {
  font-size: 12px;
  color: #7d7d7d;
}
.contentCardDetail {
  background-color: #f6f6f6;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 10px;
  margin-top: 2%;
  align-items: center;
}
.contentImgDetail {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  height: 23%;
  width: 23%;
}
.contentTextDetail {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 2%;
}
.invoiceTextDetail {
  display: flex;
  flex: 1;
  flex-direction: row;
  color: #7d7d7d;
}
.invoiceNumberDetail {
  padding-left: 1%;
  color: #529f45;
}
.nameTextDetail {
  display: flex;
  flex: 1;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
.viewPriceDetail {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.priceTextDetail {
  display: flex;
  flex: 1;
  color: #529f45;
}
.produkDetail {
  margin-right: 3%;
  font-size: 12px;
  color: #7d7d7d;
  font-family: sans-serif;
}
.detailComplaint {
  padding-top: 2%;
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
}
.textKiri {
  flex: 1;
  color: #222222;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.textKanan {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  color: #529f45;
  font-size: 14px;
  padding-right: 1%;
}
.textIsi {
  margin-top: 4%;
}
.lampiran {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.opsionalImg {
  align-self: center;
  margin-top: 3%;
  resize:both;
  /* background-color: rgb(255, 0, 0); */
  width: 100%;
  /* height: 190px; */
  border-radius: 15px;
}
.viewPenjelasan {
  margin-top: 2%;
  width: 100%;
}
.isiPenjelasan {
  color: #7d7d7d;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}
.btnDetailcomplaint {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  /* background-color: aqua; */
  width: 100%;
  align-items: center;
}
.btnTolak {
  padding: 12px;
  background-color: #d9430d;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  color: #f6f6f6;
}
.btnBalas {
  margin-top: 2%;
  padding: 12px;
  background-color: #529f45;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  color: #f6f6f6;
}
.btnTerima {
  margin-top: 2%;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  color: #7d7d7d;
  border-width: 1px;
  border-style: solid;
  border-color: #7d7d7d;
}
.viewContent {
  display: flex;
  /* background-color: #000; */
  width: 100%;
  height: fit-content;
  justify-content: center;
  margin-bottom: 3%;
}
.viewCompaintDetail {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background-color: blue; */
  width: 100%;
}
.textLampiran {
  color: #7d7d7d;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}
.viewInvoice {
  display: flex;
  flex-direction: row;
}
.titleInvoice {
  color: #7d7d7d;
  font-size: 14px;
}

