.pembayaranContainer {
    /* text-align: left; */
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    min-height: 100vh;
    /* padding-top: 8%; */
    /* min-height: 1200px; */
}

.pembayaranHeader {
    flex: 1;
    width: 100%;
    height: 200px;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.pembayaranHeaderTitle {
    display: flex;
    align-items:center; 
    /* border-bottom: 5px solid #D4D4D4; */
    /* padding-left: 4%; */
    padding-right: 4%;
    /* padding-bottom: 4%; */
    border-bottom: 5px solid #bcbc;
    height: 70px;
}

/* Daftar Pembayaran */
.pembayaranMenuHeader {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.pembayaranMenuLogo {
    width: 30px;
    height: 30px;
    margin-left: 13px;
    /* margin-right: 10px; */
}

.pembayaranBack {
    width: 20px;
    height: 20px;
    /* margin-right: 13px; */
} 

.pembayaranBackText {
    /* margin-left: 10px;
    font-weight: bold;
    font-size: 17px; */
    font-family: 'Lato-Bold', sans-serif;
    text-align: left;
    padding-left: 3%;
    font-size: 18pt;
    font-weight: 600;
}

.editButtonOK {
    margin-top: 30px;
    height: 40px;
    width: 100%;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
}

.pembayaranHeaderFilter {
    margin-top: 10px;
    display: flex;
    align-items:center; 
}

.pembayaranSearchContainer {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 92%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-left: 4%;
    margin-right: 4%;
}

.pembayaranStatusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    width: 44%;
    border-radius: 5px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-left: 4%;
}

.pembayaranDateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    width: 44%;
    margin-left: auto;
    border-radius: 5px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-right: 4%;
}

.pembayaranSearchLogo {
    width: 20px;
    height: 20px;
}

.pembayaranTextInput {
    border: 0;  
    font-family: 'Lato-Regular', sans-serif;
}

.pembayaranSelect {
    width: 100%;
    background-color: #fff;
    margin-bottom: 5px;
    border: 0;
}

.pembayaranSelect option {
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaran {
    /* margin-top: 15px; */
    text-align: left;
    padding-bottom: 13%;
}

/* @media only screen and (max-width: 1331px) {
    .pembayaran {
        margin-top: 90px;
        text-align: left;
    }
} */

/* @media (max-width: 780px) {
    .pembayaran {
        margin-top: 90px;
        text-align: left;
    }
} */

/* @media (max-width: 480px) {
    .pembayaran {
        margin-top: 30px;
        text-align: left;
    }
} */

.pembayaranMain {
    border-radius: 7px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-top: 15px;
    margin-left: 4%;
    margin-right: 4%;
}

.pembayaranTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.pembayaranTitleDate {
    font-size: 14px;
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranTitleStatusBaru {
    height: 25px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #86C6F4;
    /* border-radius: 10px; */
    border: none;
    margin-left: auto;
    padding-right: 2%;
    padding-left: 2%;
}

.pembayaranTitleStatusBaru .text {
    font-size: 14px;
    color: #1C658C;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranTitleStatusKonfirmasi {
    height: 25px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C1F4C5;
    border: none;
    margin-left: auto;
    padding-right: 2%;
    padding-left: 2%;
}

.pembayaranTitleStatusKonfirmasi .text {
    font-size: 14px;
    color: #064635;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranTitleStatusBatal {
    height: 25px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFC0D3;
    border: none;
    margin-left: auto;
    padding-right: 2%;
    padding-left: 2%;
}

.pembayaranTitleStatusBatal .text {
    font-size: 14px;
    color: #570530;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranTitleStatusRetur {
    height: 25px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafcb1;
    border: none;
    margin-left: auto;
    padding-right: 2%;
    padding-left: 2%;
}

.pembayaranTitleStatusRetur .text {
    font-size: 14px;
    color: #9b9e23;
    font-family: 'Lato-Bold', sans-serif;
}

.loginTextButtonOK {
    color: #fff;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranContent {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.pembayaranContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.pembayaranContentProduct {
    margin-bottom: 15px;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranContentPrice {
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.productQty {
    font-size: 14px;
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranMain .footer {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pembayaranMain .footer .btnComplaint {
    width: 45%;
    padding-top: 2%;
    padding-bottom: 2%;
    background: #fff;
    border: solid;
    border-width: 1px;
    border-color: #DA1212;
    border-radius: 5px;
}

.pembayaranMain .footer .btnComplaint .text {
    color: #DA1212;
    font-family: 'Lato-Bold', sans-serif;
}

.pembayaranMain .footer .btnReview {
    width: 45%;
    padding-top: 2%;
    padding-bottom: 2%;
    background: #fff;
    border: solid;
    border-width: 1px;
    border-color: #357C3C;
    border-radius: 5px;
}

.pembayaranMain .footer .btnReview .text {
    color: #357C3C;
    font-family: 'Lato-Bold', sans-serif;
}