.complaintContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
 /* height: fit-content; */
    min-height: 100vh;
  /* min-height: 1200px; */
  /* padding-top: 8%; */
}

.complaintHeader {
  flex: 1;
  width: 100%;
 height: fit-content;
  max-width: 35em;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  align-items: center;
  z-index: 1500;
}

.complaintHeaderTitle {
  display: flex;
  align-items: center;
  /* padding-left: 4%;
    padding-bottom: 3%; */
  /* border-bottom: 5px solid #D4D4D4; */
  border-bottom: 5px solid #bcbc;
  height: 70px;
}

.complaintBack {
  width: 20px;
  height: 20px;
  /* margin-right: 13px; */
}

.complaintBackText {
  font-family: "Lato-Bold", sans-serif;
  text-align: left;
  padding-left: 3%;
  font-size: 15pt;
  font-weight: 600;
}

.complaintCreateContainer {
  margin-top: 25px;
  display: flex;
  height: 50px;
  align-items: center;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 15px;
  text-decoration: none;
  color: black;
  font-family: "Lato-Bold", sans-serif;
}

.complaintCreateContainer .button {
  margin-left: auto;
}

.complaintCreateContainer .button .plus {
  height: 15px;
  width: 15px;
}

.complaintHeaderFilter {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-left: 4%;
  margin-right: 4%;
  overflow-x: scroll;
  padding-bottom: 10px;
  /* overflow: hidden; */
}

.complaintHeaderFilter::-webkit-scrollbar {
  display: none;
}
.complaintHeaderFilter {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.complaintSearchContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 92%;
  border-radius: 10px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-style: solid;
  margin-left: 4%;
  margin-right: 4%;
}

.complaintStatusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: max-content;
  height: 50px;
  margin-right: 5px;
  border-radius: 5px;
  border-width: 3px;
  border-color: #d3d3d3;
  /* border-color: #529f45; */
  border-radius: 10px;
  border-style: solid;
  /* background-color: #529f45; */
}

.complaintStatusContainer.active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: max-content;
  height: 50px;
  margin-right: 5px;
  border-radius: 5px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-radius: 10px;
  border-style: solid;
  background-color: #529f45;
}

.complaintStatusContainer.active .complaintSelect {
  font-family: "Lato-Bold", sans-serif;
  color: #fff;
}

.complaintStatusContainer .complaintSelect {
  font-family: "Lato-Bold", sans-serif;
}

.complaintSearchLogo {
  width: 20px;
  height: 20px;
}

.complaintTextInput {
  border: 0;
  width: 100%;
}

/* .complaint {
    margin-top: 38px;
} */

/* @media only screen and (max-width: 1331px) {
    .complaint {
        margin-top: 85px;
    }
} */

.complaintMain {
  border-radius: 10px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 15px;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 15px;
}

.complaintTitle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.complaintTitleDate {
  font-size: 15px;
  color: #808080;
  font-family: "Lato-Bold", sans-serif;
}

.complaintTitleInvoice {
  /* width: 30%; */
  margin-left: auto;
  color: #808080;
  font-family: "Lato-Bold", sans-serif;
}

.complaintContent {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 10px;
}

.complaintContentImage {
  height: 110px;
  width: 110px;
  margin-right: 10px;
}

.complaintContentQty {
  font-family: "Lato-Bold", sans-serif;
  color: #808080;
}

.complaintContentProduct {
  margin-bottom: 15px;
  font-family: "Lato-Bold", sans-serif;
}

.complaintContentPrice {
  font-family: "Lato-Bold", sans-serif;
}

.complaintContentPriceHalf {
  font-family: "Lato-Bold", sans-serif;
  color: #17a2b8;
  margin-bottom: 8px;
}
