.containerRegisterOldForm {
  background-color: #fff;
  max-width: 35em;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5vh;
}

.logo {
  width: 200px;
  height: 200px;
}

.judul {
  font-size: 32px;
  font-weight: bold;
  color: #529f45;
}

.iconUser {
  height: 20px;
  padding-left: 20px;
}

.containerAddressRegisterOld {
  margin-top: 15px;
  border-radius: 10px;
  border-width: 1px;
  position: relative;
  height: 40px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  /* padding-left: 5px; */
  padding-right: 5px;
  align-items: center;
  display: flex;
}

.containerInputRegisterOldForm {
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 3px 3px 3px #aaaaaa;
  border-color: #e8e8e8;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  padding-top: 7px;
}

.textInputRegisterOld {
  border-color: #bcbc;
  border-style: solid;
  border: 0;
  font-family: "Lato-Bold", sans-serif;
  font-size: 16px;
  width: 70%;
  padding-left: 20px;
}

textarea:focus,
input:focus {
  outline: none;
}

.buttonOkRegisterOldForm {
  margin-top: 30px;
  height: 40px;
  width: 50%;
  background-color: #529f45;
  border-radius: 8px;
  border: none;
}

.textButtonOK {
  color: #fff;
}
