.productReviewContent {
    max-width: 35em;
    /* width: 35em; */
   /* height: fit-content; */
    min-height: 100vh;
    background-color: #fff;
    margin-top: 10px;
    /* min-height: 1200px; */
}

.productRatingTopContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #bcbc;
    width: 100%;
    height: 200px;
    max-width: 35em;
}

.productRatingCenterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    max-width: 35em;
    overflow: auto;
}

.productRatingCenterContainer::-webkit-scrollbar {
    display: none;
  }
  
  .productRatingCenterContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

.productRatingBottomContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 630px;
    max-width: 35em;
    padding: 5px 5%;
}

.productRatingTopLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 80%;
}

.productRatingTopRightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 80%;
}

.productRatingTopRightRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.productRatingTopLeftFirst {
    /* font-family: 'Lato-Bold'; */
    font-family: 'Lato-Bold', sans-serif;
    color: #000000;
    font-size: 16pt;
    font-weight: 600;
}

.productRatingTopLeftThird {
    margin-top: 5px;
    /* font-family: 'Lato-Bold'; */
    font-family: 'Lato-Bold', sans-serif;
    color: #777;
    font-size: 14pt;
}

.productRatingWrapper {
    margin-top: 15px;
    border: 0px solid;
    width: 50%;
    margin-left: 5%;
}

.productRatingContainer {
    background-color: #fce8b1;
}

.productRatingBarCompleted {
    background-color: #fdc22f;
    /* width: 80%; */
    height: 10px;
}

.productRatingLabel {
    font-size: 20px;
    color: green;
}

.productRatingTopRightText {
    margin-top: 12px;
    margin-left: 2%;
    font-weight: 600;
}

.productRatingCenterCard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 70%;
    border-style: solid;
    border-width: 1px;
    border-color: #bcbc;
    border-radius: 15px;
    background-color: #fff;
    padding: 0px 2%;
    margin: 0px 1%;
}

.productRatingCenterStar {
    margin-top: -10px;
    margin-right: 5%;
}

.productRatingCenterText {
    /* font-family: 'Lato-Bold'; */
    font-family: 'Lato-Bold', sans-serif;
    color: #000000;
    font-size: 15pt;
    font-weight: 600;
    margin-left: 5px;
}

.productRatingBottomTitle {
    /* font-family: 'Lato-Bold'; */
    font-family: 'Lato-Bold', sans-serif;
    color: #000000;
    font-size: 16pt;
    font-weight: 600;
    margin-top: 10px;
    text-align: left;
}

.productRatingBottomCard {
    margin-top: 20px;
    width: 100%;
   /* height: fit-content; */
    /* height: 100vh; */
    border-style: solid;
    border-width: 1px;
    border-color: #bcbc;
    border-radius: 10px;
    padding: 10px 5%;
}

.productRatingBottomStar {
    margin-top: -5px;
}

.productRatingBottomText {
    /* font-family: 'Lato-Bold'; */
    font-family: 'Lato-Bold', sans-serif;
    color: #000000;
    font-size: 13pt;
    font-weight: 600;
    text-align: left;
}

.productRatingBottomDate {
    /* font-family: 'Lato-Bold'; */
    font-family: 'Lato-Bold', sans-serif;
    color: #777;
    font-size: 13pt;
    text-align: left;
    font-weight: 600;
    margin: 10px 0px;
}