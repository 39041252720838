.forgetPasswordContainer {
  background-color: #fff;
  max-width: 35em;
  /* width: 35em; */
 /* height: fit-content; */
    height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .forgetPasswordContainer {
      max-width: 30em;
      background-color: #fff;
      width: 1000px;
      height: 1000px;
      padding-top: 40%;
    } 
  } */

/* @media (max-width: 480px) {
    .forgetPasswordContainer {
      max-width: 25em;
      background-color: #fff;
      width: 1000px;
      height: 1000px;
      padding-top: 40%;
    }
  } */

.forgetPasswordLogo {
  width: 200px;
  height: 200px;
}

.forgetPasswordJudul {
  font-size: 32px;
  font-weight: bold;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
}

.forgetPasswordIconUser {
  height: 20px;
  padding-left: 20px;
}

.forgetPasswordContainerInput {
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 3px 3px 3px #aaaaaa;
  border-color: #e8e8e8;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  padding-top: 7px;
}

.forgetPasswordTextInput {
  border-color: #bcbc;
  border-style: solid;
  border: 0;
  font-family: "Lato-Bold", sans-serif;
  font-size: 16px;
  width: 70%;
  padding-left: 20px;
}

textarea:focus,
input:focus {
  outline: none;
}

.forgetPasswordDescription {
  margin-top: 20px;
  width: 60%;
  font-family: "Lato-Bold", sans-serif;
  text-align: center;
}

.forgetPasswordButton {
  margin-top: 10px;
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.forgetPasswordButton .forgetPasswordButtonSMS {
  margin-right: 20px;
  height: 35px;
  width: 45%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
  font-family: "Lato-Bold", sans-serif;
}

.forgetPasswordButton .forgetPasswordButtonWA {
  margin-left: 20px;
  height: 40px;
  width: 45%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.forgetPasswordTextButtonOK {
  color: #fff;
}
