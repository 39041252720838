.faqTopikContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    padding-top: 70px;
    /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .faqTopikContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

/* @media (max-width: 480px) {
    .faqTopikContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

.faqTopikHeader {
    flex: 1;
    width: 100%;
    /* height: 125px; */
   height: fit-content;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.faqTopikHeaderTitle {
    display: flex;
    align-items:center; 
    padding: 18px;
    border-bottom: 5px solid #D4D4D4;
    margin-bottom: 15px;
}

.faqTopikBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.faqTopikBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.faqTopikHeaderMiddle {
    margin-left: 4%;
    margin-top: 10px;
    font-family: 'Lato-Bold', sans-serif;
}

.faqTopikSearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 96%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
}

.faqTopikSearchLogo {
    width: 20px;
    height: 20px;
}

.faqTopikTextInput {
    border: 0;  
    width: 100%;
}

.faqTopikContainer .faqTopikMiddle {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 3%;
    width: 93%;
    font-family: 'Lato-Bold', sans-serif;
}

.faqTopikMain .list {
    margin-left: 4%;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Lato-Bold', sans-serif;
}
