.complainContainer {
  flex: 1;
  display: flex;
  background-color: #fff;
  max-width: 35em;
  min-height: 100vh;
  padding-bottom: 1%;
  flex-direction: column;
}
.complainHeader {
  display: flex;
}
.complainContent {
  flex: 1;
  /* margin-top: 13%; */
  padding-top: 15%;
  padding-left: 4%;
  padding-right: 4%;
}
.viewCariComplaint {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  width: 100%;
  border-radius: 10px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-style: solid;
}
.viewInputcariComplaint {
  border: 0;
}
.buttonFilter {
  padding-top: 1.7%;
  padding-bottom: 1.7%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  width: fit-content;
  border-radius: 7px;
  border-style: solid;
  border-width: 3px;
  border-color: #529f45;
  background-color: #529f45;
  color: #ffffff;
  margin-right: 3%;
  font-family: sans-serif;
}
.buttonFilterActive {
  /* background-color: aquamarine; */
  padding-top: 1.7%;
  padding-bottom: 1.7%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  width: fit-content;
  border-radius: 7px;
  border-style: solid;
  border-width: 3px;
  border-color: #76b769;
  background-color: #ffffff;
  color: #529f45;
  margin-right: 3%;
  font-family: sans-serif;
}
/* .buttonFilterActive:hover{
  border-color: #acacac;
  background-color: #ffffff;
  color: #525252;
} */
.imageCariComplaint {
  width: 5%;
  height: 5%;
}
.viewFillter {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  max-height: fit-content;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
}
.viewFillter::-webkit-scrollbar {
  display: none;
}

.viewFillter {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.viewContent {
  display: flex;
  flex-direction: column;
}
.contentDate {
  padding-top: 2%;
  padding-bottom: 0.5%;
  color: #7d7d7d;
  font-family: sans-serif;
}
.contentCard {
  background-color: #f6f6f6;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 10px;
  margin-top: 3%;
  align-items: center;
}
.contentImg {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  height: 23%;
  width: 23%;
}
.contentText {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 2%;
}
.invoiceText {
  display: flex;
  flex: 1;
  flex-direction: row;
  color: #7d7d7d;
}
.invoiceNumber {
  padding-left: 1%;
  color: #529f45;
}
.nameText {
  display: flex;
  flex: 1;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
.viewPrice {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.priceText {
  display: flex;
  flex: 1;
  color: #529f45;
}
.produk {
  margin-right: 3%;
  font-size: 12px;
  color: #7d7d7d;
  font-family: sans-serif;
}
.loading {
  background-color: #00000060;
}
