.reviewHistoryContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
 /* height: fit-content; */
    min-height: 100vh;
  /* min-height: 1200px; */
}

.reviewDetailHeader {
  flex: 1;
  width: 100%;
 /* height: fit-content; */
    /* height: 100vh; */
  max-width: 35em;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  align-items: center;
  z-index: 1500;
  border-bottom: 5px solid #d4d4d4;
}

.reviewDetailHeaderTitle {
  display: flex;
  align-items: center;
  padding-left: 4%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.reviewDetailBack {
  width: 13px;
  height: 13px;
  margin-right: 13px;
}

.reviewDetailBackText {
  margin-left: 10px;
  font-weight: bold;
  font-size: 17px;
}

.reviewDetailCard {
 /* height: fit-content; */
    /* height: 100vh; */
  margin-bottom: 2%;
  /* padding: 3%; */
  /* padding-top: 7%; */
}

.reviewDetailCard .card {
  padding: 3%;
  display: flex;
  border-width: 1px;
  border-radius: 7px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-style: solid;
  margin: 2%;
}

.reviewDetailCard .card .image {
  margin-right: 2%;
  height: 95px;
  width: 95px;
}

.reviewDetailCard .card .info {
  font-family: "Lato-Bold", sans-serif;
}

.reviewDetailCard .card .info .name {
  font-weight: bold;
  font-size: 20px;
  width: 80%;
}

.reviewDetailCard .card .info .price {
  width: 100%;
  margin-top: 3%;
  color: #529f45;
}

.reviewDetailCard .card .info .desc {
  margin-top: 3%;
  color: #888888;
}

.reviewDetailCard .card .info .descHalf {
  margin-top: 3%;
  margin-left: 5px;
  color: #17a2b8;
}

.reviewDetailCard .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2%;
  padding-top: 2%;
}

.reviewDetailCard .flex .title {
  color: #888888;
}

.reviewDetailCard .flex .value {
  width: 40%;
  text-align: right;
}

.reviewDetailCard .flex .total {
  font-size: 20px;
}

.reviewDetailCard .flex .totalPrice {
  font-size: 20px;
  color: #529f45;
}

.reviewDetailCard .footer {
  margin: 2%;
}

.reviewDetailCard .footer .desc {
  font-weight: bold;
  font-family: "Lato-Bold", sans-serif;
}

.reviewDetailCard .footer .descContent {
  font-weight: normal;
  font-family: "Lato-Regular", sans-serif;
}
