.complaintReplyContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
   min-height: 100vh;
    /* min-height: 1200px; */
    padding-bottom: 20px;
    padding-top: 70px;
}

.complaintReplyHeader {
    /* position: absolute;
    width: 520px;
    Z-index: 1090; */
    flex: 1;
    width: 100%;
    height: 50px;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

/* .complaintReply {
    margin-top: 240px;
} */

/* @media only screen and (max-width: 1331px) {
    .complaintReply {
        margin-top: 330px;
    }
} */

.complaintReplyHeaderTitle {
    display: flex;
    align-items:center; 
    padding-left: 4%;
    padding-bottom: 3%;
    border-bottom: 5px solid #D4D4D4;
}

.complaintReplyBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.complaintReplyBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.complaintReplyMain {
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 15px;
}

.complaintReplyTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.complaintReplyTitleDate {
    font-size: 15px;
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.complaintReplyContent {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.complaintReplyContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.complaintReplyContentProduct {
    margin-bottom: 15px;
    font-weight: bold;
    font-family: 'Lato-Black', sans-serif;
}

.complaintReplyContentPrice {
    font-family: 'Lato-Bold', sans-serif;
}

.complaintReplyMain table {
    font-family: 'Lato-Bold', sans-serif;
}

.complaintReplyMain table tr {
    line-height: 25px;
}

.complaintReplyDetailButton {
  margin-top: 30px;
  height: 45px;
  width: 90%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.complaintReplyButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.complaintReplyDetailTextButton {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato-Bold';
}

.complaintReplyJudulInput {
    width: 100%;
    height: 50px;
    padding: 2%;
    border-radius: 5px;
    border-color: #D3D3D3;
    border-style: solid;
}

input {
    border: none;
    width: 100%;
}

.complaintReplyDetailInput {
    margin-top: 15px;
    width: 100%;
    height: 150px;
    padding: 2%;
    border-radius: 5px;
    border-color: #D3D3D3;
    border-style: solid;
}

