.containerBalasComp {
    display: flex;
    flex: 1;
    height: max-content;
    flex-direction: column;
    background-color: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
}
.headerBalasComp {
    
    /* background-color: #ff0000; */
    margin-top: 17%;
}
.viewProdukBalasComp {
    margin-top: 7%;
}
.viewContentComp {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    padding-bottom: 7%;
    padding-left: 3%;
    justify-content: center;
    height: fit-content;
    border-radius: 15px;
    border-color: #E8E8E8;
    border-width: 2px;
    border-style: solid;
}
.viewCardComp {
    display: flex;
    flex-direction: row;
    height: fit-content;
}
.kiriContentComp {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: fit-content;
    color: #696969;
}
.kananContentComp {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: fit-content;
    text-align: left;
    color:#529F45;
}
.textContentComp {
    padding-top: 9%;
}
.textLampiranComp{
    color: #696969;
    padding-top: 3%; 
}
.hijauLampiranComp{
    padding-top: 3%; 
    color:#529F45;
}
.inputTitleComp{
    width: 100%;
    padding: 3%;
    margin-top: 3%;
    border-color: #E8E8E8;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
}
.inputTitleComp2{
    width: 100%;
    padding: 3%;
    margin-top: 3%;
    border-color: #E8E8E8;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    padding-bottom: 20%;
}
.btnBalasComp {
    width: 100%;
    padding: 3%;
    margin-top: 3%;
    border-color: #E8E8E8;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    margin-bottom: 5%;
    text-align: center;
    color: #ffffff;
    background-color: #529F45;
}