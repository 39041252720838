.complaintCreateContainer1 {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
   min-height: 100vh;
    padding-bottom: 15px;
    padding-top: 70px;
}

/* @media (max-width: 780px) {
    .complaintCreateContainer1 {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: max-content;
        padding-bottom: 15px;
    }
    
} */

/* @media (max-width: 480px) {
    .complaintCreateContainer1 {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: max-content;
        padding-bottom: 15px;
    }
    
} */

.complaintCreateHeader {
    flex: 1;
    width: 100%;
   /* height: fit-content; */
    height: 100vh;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.complaintCreateHeaderTitle {
    display: flex;
    align-items:center; 
    padding-left: 4%;
    padding-bottom: 3%;
    border-bottom: 5px solid #D4D4D4;
}

.complaintCreateBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.complaintCreateBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.complaintCreateBacFooterkText {
    padding: 10px;
}

.complaintCreateMain {
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 15px;
}

.complaintCreateMainSelected {
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 15px;
    background-color: #D3D3D3;
}

.complaintCreateTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.complaintCreateTitleDate {
    font-size: 15px;
    color: #808080;
}

.complaintCreateContent {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.complaintCreateContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.complaintCreateContentProduct {
    margin-bottom: 15px;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}

.complaintCreateMain table tr {
    line-height: 25px;
}

.complaintCreateDetailButton {
  margin-top: 30px;
  height: 45px;
  width: 90%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.complaintCreateButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.complaintCreateDetailTextButton {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}

.complaintCreateJudulInput {
    width: 100%;
    height: 50px;
    padding: 2%;
    border-radius: 5px;
    border-color: #D3D3D3;
    border-style: solid;
}

input {
    border: none;
    width: 100%;
}

.complaintCreateDetailInput {
    margin-top: 15px;
    width: 100%;
    height: 150px;
    padding: 2%;
    border-radius: 5px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-bottom: 25px;
}

.complaintCreateFooterInput {
    border: none;
}

.complaintCreateFooterDropdown {
    margin-top: -15px;
    margin-bottom: 20px;
}

.complaintCreateItemTrashContainer {
    display: flex;
    height: 65px;
    width: 30%;
}

.complaintCreateItemQtyMinus {
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 20%;
    height: 50%;
}

.complaintCreateItemQtyMinusImage {
    width: 40%;
    height: 40%;
}

.complaintCreateItemQtyInput {
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    color: black;
    font-family: 'Lato-Regular', sans-serif;
    text-align: center;
    width: 20%;
    height: 50%;
}

.complaintCreateItemQtyPlus {
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    background-color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 20%;
    height: 50%;
}

.complaintCreateItemQtyPlusImage {
    width: 40%;
    height: 40%;
}

.complaintCreateItemDropdownContainer {
    display: flex;
    height: 40px;
}

.complaintCreateItemSelect {
    width: 250px;
    border-style: solid;
    border-color: #DCDCDC;
    border-radius: 10px;
    background-color: #fff;
    padding: 8px;
    padding-right: 8px;
}

.complaintCreateFooterUploadLampiran {
   height: fit-content;
    width: 20%;
    margin-right: 2%;
}

.complaintCreateFooterUpload {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.complaintCreateItemUploadContainer .upload {
    height: 100px;
    padding: 8px;
    padding-left: 8px;
    width: 90%;
    background-color: #fff;
    text-align: center;
    border-style: solid;
    border-color: #DCDCDC;
    border-radius: 25px;
}


.complaintCreateFooterUploadLampiranHasil {
   /* height: fit-content; */
    height: 100vh;
    width: 100%;
    margin-right: 2%;
}

/* .complaintCreateFooterUploadHasil {
    display: flex;
    flex-direction: row;
    width: 100%;
} */

.complaintCreateItemUploadContainerHasil .uploadHasil {
    height: 170px;
    width: 100%;
    background-color: #fff;
    text-align: center;
    border-style: solid;
    border-color: #DCDCDC;
    border-radius: 25px;
    resize: auto;
    margin-bottom: 10px;
}

.complaintCreateItemQtyHasilImage {
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.complaintCreateDetailButtonDisabled {
    margin-top: 30px;
    height: 45px;
    width: 90%;
    background-color: #E07126;
    border-radius: 10px;
    border: none;
  }