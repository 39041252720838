.registerNewContainer {
  min-height: 100vh;
  max-width: 35em;
  padding-top: 15%;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.registerNewLogo {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.registerNewJudul {
  font-size: 32px;
  font-weight: bold;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
  margin-left: 3%;
}

.registerNewIconUser {
  display: flex;
  /* margin-top: 8px; */
  width: 20px;
  height: 20px;
  position: absolute;
  /* top: 30%; */
  margin-top: 7px;
  align-self: flex-start;
  margin-left: 2%;
}

.registerNewContainerInput {
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 2px 2px 2px #aaa;
  border-color: #ddd;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  padding-left: 5px;
  padding-right: 5px;
  /* background-color: #529f45; */
  display: flex;
  /* justify-content: baseline; */
  align-items: center;
  flex-direction: column;
}

.registerNewTextInput {
  border-color: #bcbc;
  border-style: solid;
  margin-bottom: 5px;
  border: 0;
  position: absolute;
  top: 30%;
  /* margin-left: -110px; */
  width: 70%;
}

.registerNewSelect {
  width: 70%;
  background-color: #fff;
  margin-bottom: 5px;
  border: 0;
  position: absolute;
  top: 30%;
  /* margin-left: -110px; */
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.registerNewButtonOk {
  height: 40px;
  width: 60%;
  background-color: #529f45;
  border-radius: 8px;
  border: none;
  margin: 0 auto;
  margin-top: 30px;
  /* margin-bottom: 50px; */
}

.registerNewTextButtonOK {
  color: #fff;
}

.textAlert {
  color: red;
  margin-top: 5px;
  margin-bottom: -3%;
  position: relative;
  left: 5px;
  top: 5px;
}

/* .loading {
  flex: 1;
  display: flex;
  background-color: rgba(52, 52, 52, 0.2);
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
} */

.containerCaptcha {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.captcha {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 5px 5px 5px #aaaaaa;
  border-color: #d3d3d3;
  border-style: solid;
  position: relative;
  height: 50px;
  width: 60%;
  /* margin-left: 20%; */
  /* margin-right: 20%; */
  padding-left: 10px;
  padding-right: 10px;
}

.captchaInput {
  border-color: #d3d3d3;
  border-radius: 10px;
  border-style: solid;
  border: 0.2;
  text-align: center;
  /* margin-left: ddd-20px; */
  width: 35%;
  height: 40px;
}

.captchaImage {
  height: 100%;
  width: 100%;
  resize: auto;
}

.buttonReload {
  border-color: #d3d3d3;
  border-radius: 8px;
  height: 60%;
  width: 20%;
}

.captchaText {
  font-weight: 500;
  font-size: 11pt;
  font-family: "Lato-Bold", sans-serif;
}

.captchaTextBtn {
  font-weight: 500;
  font-size: 12pt;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
}

.buttonCheckCaptcha {
  margin-top: 30px;
  height: 40px;
  width: 60%;
  background-color: #e07126;
  border-radius: 8px;
  border: none;
}
.nextBtnRegisterNew {
  margin: 5% 20% 15% 20%;
  background-color: #529f45;
  text-align: center;
  padding: 2% 0 2% 0;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
  border-radius: 5px;
}
.btnTextColor {

  font-family: "Lato-Bold", sans-serif;
  font-size: 10pt;
  overflow: hidden;
  text-overflow: ellipsis;
}