.faqDetailContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    /* padding-top: 8%; */
    /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .faqDetailContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

/* @media (max-width: 480px) {
    .faqDetailContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

.faqDetailHeader {
    flex: 1;
    width: 100%;
    /* height: 125px; */
   height: fit-content;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.faqDetailHeaderTitle {
    display: flex;
    align-items:center; 
    padding: 18px;
    border-bottom: 5px solid #D4D4D4;
    margin-bottom: 15px;
}

.faqDetailBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.faqDetailBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.faqDetailHeaderMiddle {
    margin-left: 4%;
}

.faqDetailSearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 92%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-top: 2%;
    margin-left: 4%;
    margin-right: 4%;
}

.faqDetailSearchLogo {
    width: 20px;
    height: 20px;
}

.faqDetailTextInput {
    border: 0;  
    width: 100%;
}

.faqDetailContainer .faqDetailMiddle {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 3%;
    width: 93%;
}

.faqDetailMain .title {
    font-weight: bold;
    margin-top: 5%;
    margin-left: 4%;
    font-family: 'Lato-Bold', sans-serif;
}

.faqDetailMain .list {
    margin-left: 4%;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Lato-Bold', sans-serif;
}
