.searchListContainer {
    max-width: 35em;
    /* width: 35em; */
   /* height: fit-content; */
   min-height: 100vh;
    /* min-height: 1200px; */
    background-color: #fff;
    padding-top: 5%;
    padding-bottom: 10vh;
}

.searchListContainer .header1 {
    width: 100%;
   /* height: fit-content; */
    height: 100vh;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
    margin-bottom: 2%;
}

.searchListContainer .header1 .headerContainer {
    border-bottom: 5px solid #bcbc;
    display: flex;
    align-items: center;
}

.searchListContainer .headerContainerLogo {
    width: 20%;
}

.searchListContainer .headerContainerLogo .headerLogo {
    width: 60px;
    height: 60px;
}

.searchListContainer .header1 .headerContainer .headerSearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 50%;
    height: 50px;
    margin-bottom: 1%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
}

.searchListContainer .headerSearchLogo {
    width: 20px;
    height: 20px;
}

.searchListContainer .headerTextInput {
    border: 0;  
}

input:focus{
    outline: none;
}

.searchListContainer .header1 .headerContainerMenu {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
}

.searchListContainer .headerContainerMenu .headerMenuLogo {
    width: 30px;
    height: 30px;
}

.searchListContainer .content {
    border-radius: 10px;
    border-width: 2px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 13px;
    margin-top: 19px;
    margin-left: 4%;
    margin-right: 4%;
    text-align: left;
}

.searchListContainer .content .product { 
    display: flex;
}

.searchListContainer .content .product .image { 
    height: 70px;
    width: 70px;
}

.searchListContainer .content .product .desc {
    margin-top: 10px;  
    margin-left: 2%;
}

.searchListContainer .content .product .name {  
    font-family: 'Lato-Bold', sans-serif;
}

.searchListContainer .content .product .price {  
    margin-top: 10px;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}