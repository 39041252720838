.success-alert-images-dialog {
  display: flex;
  /* background-color: aqua; */
  flex-direction: column;
  padding: 0 1% 1% 1%;
  justify-content: end;
  font-family: "Lato-Bold", sans-serif;
}
.div-alert-success-content {
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  padding: 2%;
  border-radius: 5px;
}
.div-alert-success {
  margin: 5% 0 5% 3%;
  color: #529f45;
  font-size: 20px;
}
.div-alert-success-desc {
  font-size: 16px;
  text-align: center;
}
.div-btn-success {
  display: flex;
  /* background-color: aqua; */
  margin: 5% 0 5% 0;
  text-align: center;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.btn-close-success {
  background-color: #529f45;
  padding: 2.3% 20% 2.3% 20%;
  color: #ffffff;
  border-radius: 5px;
}
