.faqContainer {
    text-align: left;
    /* width: 35em; */
    max-width: 35em;
    background-color: #fff;
   /* height: fit-content; */
    min-height: 100vh;
    /* min-height: 1200px; */

}

.faqHeader {
    flex: 1;
    width: 100%;
    /* height: 125px; */
   height: fit-content;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
    padding-bottom: 3%;
}

.faqHeaderTitle {
    display: flex;
    align-items:center; 
    padding: 18px;
    border-bottom: 5px solid #D4D4D4;
    margin-bottom: 15px;
}

.faqBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.faqBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.faqHeaderMiddle {
    padding-top: 20%;
    margin-left: 4%;
    font-family: 'Lato-Bold', sans-serif;
    margin-bottom: 3%;
}

.faqSearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 92%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-top: 2%;
    margin-left: 4%;
    margin-right: 4%;
}

.faqSearchLogo {
    width: 20px;
    height: 20px;
}

.faqTextInput {
    border: 0;  
    width: 100%;
}

.faqContainer .faqMiddle {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 10px;
    width: 93%;
}

/* @media only screen and (max-width: 1331px) {
    .faqContainer .faqMiddle {
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 105px;
        width: 93%;
    }
} */


.faqMiddle .main {
    width: 100%;
    display: flex;
    margin-top: 2%;
    flex-direction: row;
    overflow: auto;
}

.faqMiddle .main::-webkit-scrollbar {
    display: none;
  }
  
  .faqMiddle .main {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

.faqMiddle .item {
    margin-right: 8%;
}

.faqMiddle .main .item .round {
    margin-top: 5%;
    margin-bottom: 3%;
    border-width: 3px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border-color: #D3D3D3;
    background-color: #D9DDDC;
    border-style: solid;
    text-align: center;
}

.faqMiddle .main .item .image {
    margin-top: 15px;
    height: 45px;
    height: 45px;
}

.faqMiddle .title {
    font-family: 'Lato-Bold', sans-serif;
}

.faqMiddle .main .title {
    margin-top: 10px;
    text-align: center;
    font-family: 'Lato-Bold', sans-serif;
}

.faqMain .title {
    font-weight: bold;
    margin-top: 5%;
    margin-left: 4%;
    font-family: 'Lato-Bold', sans-serif;
}

.faqMain .list {
    margin-left: 4%;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Lato-Bold', sans-serif;
}
