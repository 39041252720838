.transactionContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    /* min-height: 1200px; */
}

.transactionHeader {
    flex: 1;
    width: 100%;
    height: 195px;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.transactionHeaderTitle {
    display: flex;
    align-items:center; 
    /* border-bottom: 5px solid #D4D4D4; */
    border-bottom: 5px solid #bcbc;
    height: 70px;
}

.transactionBack {
    width: 20px;
    height: 20px;
    /* margin-right: 13px; */
} 

.transactionBackText {
    font-family: 'Lato-Bold', sans-serif;
    text-align: left;
    padding-left: 3%;
    font-size: 18pt;
    font-weight: 600;
}

.editButtonOK {
    margin-top: 30px;
    height: 40px;
    width: 100%;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
}

.transactionHeaderFilter {
    margin-top: 10px;
    display: flex;
    align-items:center; 
}

.transactionSearchContainer {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 92%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-left: 4%;
    margin-right: 4%
}

.transactionStatusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    width: 44%;
    border-radius: 5px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-left: 4%;
}

.transactionDateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    width: 44%;
    margin-left: auto;
    border-radius: 5px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-right: 4%;
}

.transactionSearchLogo {
    width: 20px;
    height: 20px;
}

.transactionTextInput {
    border: 0;  
}

.transactionSelect {
    width: 100%;
    background-color: #fff;
    margin-bottom: 5px;
    border: 0;
}

.transaction {
    margin-top: 48px;
}

/* @media only screen and (max-width: 1331px) {
    .transaction {
        margin-top: 90px;
    }
} */

.transactionMain {
    border-radius: 7px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-top: 15px;
    margin-left: 4%;
    margin-right: 4%;
}

.transactionTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.transactionTitleDate {
    font-size: 14px;
    color: #808080;
}

.transactionTitleStatus {
    height: 40px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
}

.transactionTitleStatusText {
    font-size: 14px;
    color: #fff;
}

.loginTextButtonOK {
    color: #fff;
    font-family: 'Lato-Bold', sans-serif;
}

.transactionTitleStatus {
    margin-left: auto;
}

.transactionContent {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.transactionContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.transactionContentProduct {
    margin-bottom: 15px;
}

.transactionContentPrice {
    color: #808080;
}
