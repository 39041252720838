.promoCalendarContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
  /* height: fit-content; */
  height: 100vh;
  /* padding-top: 8%; */
  /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .promoCalendarContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

/* @media (max-width: 480px) {
    .promoCalendarContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

.promoCalendarHeader {
  flex: 1;
  width: 100%;
  /* height: fit-content; */
  min-height: 100vh;
  max-width: 35em;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  align-items: center;
  z-index: 1500;
}

.promoCalendarHeaderTitle {
  display: flex;
  align-items: center;
  padding: 18px;
  border-bottom: 5px solid #d4d4d4;
  font-family: "Lato-Bold", sans-serif;
  /* margin-bottom: 15px; */
}

.promoCalendarBack {
  width: 13px;
  height: 13px;
  margin-right: 13px;
}

.promoCalendarBackText {
  margin-left: 10px;
  font-weight: bold;
  font-size: 17px;
}

.promoCalendarBanner {
  padding: 4%;
}

.promoCalendarBannerImage {
  height: 200px;
  width: 100%;
  border-radius: 7px;
}

.promoProduct {
  padding: 30px;
  padding-top: 20px;
  /* border-radius: 20px; */
  -webkit-box-shadow: 0 5px 5px #d4d4d4;
  -moz-box-shadow: 0 5px 5px #d4d4d4;
  box-shadow: 0 5px 5px #d4d4d4;
}

.promoProductTitle {
  font-family: "Lato-Black", sans-serif;
  font-weight: bold;
  font-size: 12pt;
  text-align: left;
  margin-left: 5px;
}

.promoProductContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: -15px;
  width: 100%;
  overflow-x: scroll;
}

.promoProductContainer::-webkit-scrollbar {
  display: none;
}

.promoProductContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.promoProductContainerHidden {
  overflow: hidden;
}

.promoProductRecomenContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  max-width: 35em;
}

.promoProductRecomenItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  /* height: fit-content; */
  height: 100vh;
  width: 30%;
  min-width: 150px;
  margin: 2px 8px 15px 8px;
  padding-bottom: 25px;
}

.promoProductItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  height: fit-content;
  width: 32%;
  min-width: 150px;
  margin: 2px 8px 15px 8px;
  padding-bottom: 25px;
}

.promoProductImage {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  margin: 5px;
  margin-top: 25px;
  max-width: 90%;
  max-height: 90%;
}

.promoProductItemTitle {
  margin: 5px 10px;
  font-family: "Lato-Regular", sans-serif;
  text-align: left;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.promoProductItemPrice {
  font-family: "Lato-Regular", sans-serif;
  color: #529f45;
  display: flex;
  font-size: 16pt;
  margin: 0px 10px;
}

.promoProductItemRating {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.promoProductItemRatingValue {
  font-family: "Lato-Bold", sans-serif;
  margin-left: 5%;
  text-align: left;
}

.promoProductItemButton {
  margin: auto;
  margin-top: 5px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #529f45;
  border-radius: 10px;
  width: 80%;
  height: 30px;
}

.promoProductItemButtonBeli {
  text-align: center;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
}

.promoProductItemPromo {
  display: flex;
  position: absolute;
  margin: 7px;
  padding: 4px 10px;
  background-color: #b4e5ed;
  justify-content: center;
  align-items: center;
}

.promoProductItemPromoText {
  font-family: "Lato-Bold", sans-serif;
  color: #17a2b8;
  font-size: 12pt;
  font-weight: bold;
}

.promoCalendarContent {
  padding-top: 10vh;
}


.rewardCard {
  /* -webkit-box-shadow: 0 10px 5px #d4d4d4;
  -moz-box-shadow: 0 10px 5px #d4d4d4;
  box-shadow: 0 10px 5px #d4d4d4; */
  /* height: fit-content; */
  /* height: 100vh; */
  margin-bottom: 2%;
  padding: 3%;
  font-family: "Lato-Bold", sans-serif;
}

.rewardCard .productList {
  border-radius: 6px;
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  /* margin-top: 2%; */
  margin-bottom: 2%;
}

.rewardCard .productList .main {
  display: flex;
  justify-content: center;
}

.rewardCard .productList .main .content {
  margin-left: 2%;
}

.rewardCard .productList .main .content .price {
  margin-top: 5px;
  color: #888888;
  font-family: "Lato-Bold", sans-serif;
}

.rewardCard .productList .main img {
  height: 100%;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.rewardCard .productList .footer {
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}

.rewardCard .productList .footer .two {
  color: #000000;
  font-family: "Lato-Bold", sans-serif;
}

.rewardDetailCardContainer {
  padding: 15px;
  width: 92%;
  border-radius: 10px;
  border-width: 3px;
  border-color: #D3D3D3;
  border-style: solid;
  margin-top: 2%;
  margin-left: 4%;
  margin-right: 4%;
  display: flex;
  justify-content: space-between;
}

.rewardDetailCardContainer .first {
  font-family: 'Lato-Bold', sans-serif;
  width: 110px;
  display: flex;
}

.rewardDetailCardContainer .two {
  font-family: 'Lato-Bold', sans-serif;
  width: 50vw;
  display: flex;
  margin-left: 5px;
}

.rewardDetailButtonContainer {
  width: 100%;
  display: flex;
  padding-left: 5%;
}

.rewardDetailButton {
  width: 38em;
  height: 50px;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
  margin-top: 25%;
  position: fixed;
  bottom: 2vh;
}

@media (max-width: 767px) {
  .rewardDetailButton {
    width: 71%;
  }
}

@media (max-width: 480px) {
  .rewardCard .productList {
    margin-top: 4vh;
  }

  .rewardDetailButton {
    width: 91%;
  }
}
