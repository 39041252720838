.deliveryContent {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  /* height: 100vh; */
  background-color: #fff;
  margin-top: 8%;
  padding-bottom: 10%;
}

.deliveryUserContainer {
  width: 100%;
  max-width: 35em;
  /* height: fit-content; */
  /* height: 100vh; */
  border-bottom: 3px solid #bcbc;
}

.deliveryUserBubbleInfo {
  width: 100%;
  max-width: 35em;
  border-bottom: 3px solid #bcbc;
  justify-content: center;
  display: flex;
}

.deliveryUserData {
  display: flex;
  flex-direction: row;
  max-width: 35em;
  /* height: fit-content; */
  /* height: 100vh; */
  margin: 2% 3%;
}

.deliveryUserDataLeft {
  margin-right: 4%;
  /* width: 10%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.deliveryUserDataRight {
  width: 80%;
  text-align: left;
}

.deliveryIcon {
  max-height: 35px;
}

.deliveryUserDataRightTitle {
  margin-bottom: 5px;
  font-family: "Lato-Bold", sans-serif;
  font-weight: 700;
  font-size: 14pt;
}

.deliveryUserDataRightText {
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
}

.deliveryProductContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  /* padding-bottom: 10px; */
  width: 100%;
  max-width: 35em;
  /* height: fit-content; */
  /* height: 100vh; */
  background-color: #FFF;
}
.nameDistibutor {
  display: flex;
  width: 100%;
  margin-bottom: 3%;
  padding-left: 3%;
  font-family: "Lato-Regular", sans-serif;
}
.line-pengiriman {
  border-bottom: 15px solid #c7c6c6;
  margin-bottom: 15px;
  margin-top: 15px;
}
.deliveryProductCard {
  display: flex;
  flex-direction: row;
  width: 95%;
  max-width: 35em;
  /* height: fit-content; */
  /* height: 100vh; */
  border-style: solid;
  border-width: 1px;
  border-color: #bcbc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 0px 2px #ddd;
  padding: 10px 2%;
  margin-bottom: 15px;
}

.deliveryProductImageContainer {
  width: 26%;
  margin-top: 15px;
}

.deliveryProductImage {
  max-width: 90%;
}

.deliveryProductInfo {
  margin-top: 10px;
  margin-left: 3%;
  width: 70%;
  text-align: left;
}

.deliveryProductInfoTitle {
  font-family: "Lato-Regular", sans-serif;
  font-size: 14pt;
}

.deliveryProductInfoDetail {
  margin-top: 15px;
  font-family: "Lato-Regular", sans-serif;
  font-size: 13pt;
}

.deliveryProductInfoDetailHalf {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Lato-Regular", sans-serif;
  font-size: 13pt;
  color: #17a2b8;
}

.deliveryProductInfoPrice {
  font-family: "Lato-Regular", sans-serif;
  color: #529f45;
  font-size: 16pt;
}

.deliveryRewardProductGeneralContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 4%;
  width: 100%;
  background-color: #fff;
  border-top: 3px solid #d1d1d1;
  border-bottom: 3px solid #d1d1d1;
}

.deliveryRewardProductGeneralTitle {
  font-family: "Lato-Regular", sans-serif;
  font-size: 14pt;
}

.deliveryRewardProductGeneralCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #bcbc;
  border-radius: 5px;
  width: 100%;
  height: fit-content;
}

.deliveryRewardIconContainer {
  display: flex;
  flex-direction: row;
  max-width: 20%;
  padding: 10px 4%;
}

.deliveryRewardIcon {
  max-height: 40px;
}

.deliveryRewardText {
  font-family: "Lato-Regular", sans-serif;
  color: black;
  font-size: 13pt;
}


.deliveryDetailBottomContainer {
  display: flex;
  padding: 30px 2%;
}


.deliveryDetailTopRightContainer {
  width: 80%;
}


.deliveryDetailBottomLeftContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  /* margin-left: 5%; */
}

.deliveryDetailBottomRightContainer {
  display: flex;
  justify-content: center;
  width: 50%;
}

.deliveryDetailBottomRightContainer {
  display: flex;
  justify-content: center;
}

.deliveryDetailBottomLeftTitle {
  font-family: "Lato-Regular", sans-serif;
  color: black;
  font-size: 20pt;
  font-weight: 500;
}

.deliveryDetailBottomLeftText {
  font-family: "Lato-Regular", sans-serif;
  color: #529f45;
  font-size: 18pt;
}

.deliveryDetailButton {
  width: 90%;
  background-color: #e07126;
  border-radius: 10px;
  border-color: #fff;
}

.deliveryDetailButtonText {
  font-family: "Lato-Regular", sans-serif;
  font-size: 14pt;
  color: #fff;
}

.deliveryDetailContainer{
  display: flex;
  flex-direction:column ;
  padding: 0 10px 0 10px;
} 
.delivery-btn-promo{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border:3px solid #bcbc;
  background-color: #FFF;
  padding: 7px 10px 7px 10px;
  border-radius: 10px;
}
.content-pakai-promo {
  width: 100%;
  padding: 0 15px 0 15px;
}
.content-pakai-promo-pencarian {
  width: 25px;
  height: 25px;
}
.delivery-btn-promo-icon {
  width: 25px;
  height: 25px;
}
.delivery-list-promo{
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.delivery-detail-total{
  padding: 5px 10px 0 10px;
  font-family: "Lato-Regular", sans-serif;
  font-size: 11pt;
}
.delivery-btn-promo-text{
  font-family: "Lato-Regular", sans-serif;
  font-size: 12pt;
}
.delivery-detail-text-pengiriman{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top:10px ;
}
.deliveryDetailIcon{
  width: 25px;
  height: 25px;
  margin: 0 5px 0 0 ;
}
.delivery-item{
  display: flex;
  width: 100%;
  background-color: #FFF;
  font-family: "Lato-Regular", sans-serif;
  font-size: 10pt;
  border: 3px solid #bcbc;
  flex-direction: row;
  border-radius: 10px;
}
.delivery-item-image{
  display: flex;
  justify-content: center;
  align-items: center;
}
.delivery-item-code{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 2%;
}
.delivery-item-image-image{
 border-top-left-radius: 5px; 
 border-bottom-left-radius: 5px; 
 width: 70px;
 height: 70px;
}
.delivery-detail-text-pengiriman-detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivery-item-code-code{
  width: 100%;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 16px;    
   max-height: 32px;      
   -webkit-line-clamp: 1; 
   -webkit-box-orient: vertical;
}
@media (max-width: 480px) {
  .deliveryDetailBottomContainer {
    display: flex;
    flex-direction: column;
  }
  .deliveryDetailBottomLeftContainer {
    width: 100%;
  }
  .deliveryDetailBottomRightContainer {
    width: 100%;
  }
  .deliveryDetailButton {
    padding: 10px 0px;
  }
}
