.profileDistributorTextBold {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 12pt;
    color: #529f45
}

.profileDistributorTextMed {
    font-family: 'Lato-Medium', sans-serif;
    font-size: 12pt;
    color: #529f45
}

.profileDistributorTextReg {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 12pt;
    color: #529f45
}

.profileDistributorTabMenu {
    width: 35%;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #E8E8E8;
    justify-content: center;
    display: flex;
    padding: 10px 0px;
}

.profileDistributorTabMenuActive {
    width: 35%;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: #529f45;
    justify-content: center;
    display: flex;
    padding: 10px 0px;
}

.profileDistributorContainerProducts {
    padding-top: 20px;
    border-radius: 20px;
    padding-left: 5%;
    padding-right: 5%;
}

.profileDistributorTabCategory {
    width: 30%;
    justify-content: center;
    display: flex;
}

.profileDistributorIconActive {
    width: 100%;
    height: 100%;
}

.profileDistributorIconContainerActive {
    padding: 5px 5px;
    background-color: #7EDC6E;
    border-radius: 35px;
    width: 35px;
    height: 35px;
}

.profileDistributorIconContainer {
    padding: 5px 5px;
    background-color: #D9D9D9;
    border-radius: 35px;
    width: 35px;
    height: 35px;
}

.profileDistributorTwoTopButton {
    border-style: solid;
    padding: 10px 45px;
    border-width: 2px;
    border-color: #E8E8E8;
    border-radius: 12px;
}

.profileDistributorFilter {
    border-style: solid;
    padding: 10px 10px;
    border-width: 2px;
    border-color: #E8E8E8;
    border-radius: 12px;
    width: 30%;
    justify-content: center;
    display: flex;
}

.profileDistributorTopButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}