.ulasanContainer {
  flex: 1;
  display: flex;
  background-color: #fff;
  max-width: 35em;
  min-height: 100vh;
  padding-bottom: 1%;
  flex-direction: column;
}
.ulasanHeader {
  display: flex;
}
.viewContent {
  margin-top: 5%;
  font-family: sans-serif;
  font-size: 15px;
  padding-left: 1%;
}
.satu {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  background-color: #f6f6f6;
  border-radius: 10px;
}
.ulasanContent {
  flex: 1;
  /* margin-top: 13%; */
  padding-top: 17%;
  padding-left: 4%;
  padding-right: 4%;
}
.viewStar {
  flex: 1;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}
.viewTextUser {
  flex: 1;
  margin-top: 1%;
}
.viewTextUlasan {
  flex: 1;
  margin-top: 1%;
}
.viewImgDetailUlasan {
  width: 90px;
  height: 90px;
  background-color: cadetblue;
  border-radius: 10px;
  resize: cover;
}
.viewTextUlasan {
  margin-left: 3%;
  padding-right: 2%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.viewUlasanTitle {
  font-weight: 600;
}
.viewValueUlasan {
  font-size: 13px;
  color: #7d7d7d;
}
.viewUlasanLainnya {
  float: right;
  align-items: flex-end;
  font-size: 12px;
  color: #7d7d7d;
}
.textNamaUser {
  font-size: 14px;
}
.textTotalHarga {
  font-size: 14px;
  color: #529f45;
}
