.complaintChooseContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    min-height: 100vh;
    padding-top: 70px;
    /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .complaintChooseContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
        padding-top: 70px;
    }
} */

/* @media (max-width: 480px) {
    .complaintChooseContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
        padding-top: 70px;
    }
} */

.complaintChooseHeader {
    flex: 1;
    width: 100%;
    height: 50px;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.complaintChooseTile {
    padding: 2%;
    font-family: 'Lato-Bold', sans-serif;
}

a {
    text-decoration: none;
    color: black;
}

.complaintChooseHeaderTitle {
    display: flex;
    align-items:center; 
    padding-left: 4%;
    padding-bottom: 3%;
    border-bottom: 5px solid #D4D4D4;
}

.complaintChooseBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.complaintChooseBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.complaintChooseMain {
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 15px;
}

.complaintChooseTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.complaintChooseTitleDate {
    font-size: 15px;
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.complaintChooseContent {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.complaintChooseContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.complaintChooseContentProduct {
    margin-bottom: 15px;
    font-weight: bold;
    font-family: 'Lato-Black', sans-serif;
}

.complaintChooseContentPrice {
    font-family: 'Lato-Black', sans-serif;
}

.complaintChooseMain table tr {
    line-height: 25px;
}

.complaintChooseDetailButton {
  margin-top: 30px;
  height: 45px;
  width: 90%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.complaintChooseButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.complaintChooseDetailTextButton {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato-Bold';
}

.complaintChooseJudulInput {
    width: 100%;
    height: 50px;
    padding: 2%;
    border-radius: 5px;
    border-color: #D3D3D3;
    border-style: solid;
}

input {
    border: none;
    width: 100%;
}

.complaintChooseDetailInput {
    margin-top: 15px;
    width: 100%;
    height: 150px;
    padding: 2%;
    border-radius: 5px;
    border-color: #D3D3D3;
    border-style: solid;
}

