.reviewHistoryContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    padding-top: 70px;
    /* min-height: 1200px; */
}

.reviewHistoryHeader {
    flex: 1;
    width: 100%;
   /* height: fit-content; */
    height: 100vh;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.reviewHistoryHeaderTitle {
    display: flex;
    align-items:center; 
    padding-left: 4%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.reviewHistoryBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.reviewHistoryBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.reviewHistoryButtonFilter {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    border-bottom: 2px solid #D4D4D4;
}

.reviewHistoryButtonFilter button {
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    border: none;
    background-color: #fff;
}

.reviewHistorySearch {
    width: 100%;
    padding: 2%;
}

.reviewHistorySearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    width: 100%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
}

.reviewHistorySearchLogo {
    width: 20px;
    height: 20px;
}

.reviewHistoryTextInput {
    border: 0;  
}

.reviewHistorySearch .input {
    height: 50px;
    border-radius: 6px;
    border-color: #D3D3D3;
    border-style: solid;
}

.reviewHistoryCard {
   /* height: fit-content; */
    min-height: 100vh;
    margin-bottom: 2%;
    /* padding: 3%; */
    padding-top: 10px;
}

.reviewHistoryCard .card {
    padding: 3%;
    display: flex;
}

.reviewHistoryCard .card .image {
    margin-right: 2%;
    height: 120px;
    width: 120px;
}

.reviewHistoryCard .card .info  {
    font-family: 'Lato-Bold', sans-serif;
    text-align: left;
}

.reviewHistoryCard .card .info .name {
    font-weight: bold;
    font-size: 20px;
    width: 80%;
}

.reviewHistoryCard .card .info .date {
    width: 100%;
    margin-top: 1%;
    color: #888888;
}

.reviewHistoryCard .card .info .star {
    margin-top: 1%;
}

.reviewHistoryCard .flex {
    display: flex;
    justify-content: space-between;
    align-items:center; 
    padding-bottom: 2%;
    padding-top: 2%;
}

.reviewHistoryCard .flex .title {
    color: #888888;
}

.reviewHistoryCard .flex .value {
    width: 40%;
    text-align: right;
}

.reviewHistoryCard .flex .total {
    font-size: 20px;
}

.reviewHistoryCard .flex .totalPrice {
    font-size: 20px;
    color: #529f45;
}

.transactionDetailCardLast {
   /* height: fit-content; */
    height: 100vh;
    margin-bottom: 10%;
    padding: 3%;
}

.transactionDetailCardLast .flex {
    display: flex;
    justify-content: space-between;
    align-items:center; 
    padding-bottom: 2%;
    padding-top: 2%;
}

.transactionDetailCardLast .flex .title {
    color: #888888;
}

.transactionDetailCardLast .flex .value {
    width: 40%;
    text-align: right;
}

.transactionDetailCardLast .flex .total {
    font-size: 20px;
}

.transactionDetailCardLast .flex .totalPrice {
    font-size: 20px;
    color: #529f45;
}

.transactionDetailCard .productList {
    border-radius: 6px;
    border-width: 2px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-top: 2%;
    margin-bottom: 2%;
}

.transactionDetailCard .productList .main {
    display: flex;
}

.transactionDetailCard .productList .main .content {
    margin-left: 2%;
}

.transactionDetailCard .productList .main img {
    height: 95px;
    width: 95px;
}

.transactionDetailCard .productList .footer {
    padding: 3px;
}

.transactionDetailCard .productList .footer .first {
    width: 100px;
    color: #888888;
    font-family: 'Lato-Regular', sans-serif;
}

.transactionDetailFooter {
    flex: 1;
    width: 100%;
    max-width: 35em;
    position: fixed;
    padding: 10px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    text-align: center;
    align-items: center;
}

/* @media (max-width: 780px) {
    .transactionDetailFooter {
        flex: 1;
        width: 100%;
        max-width: 30em;
        position: fixed;
        padding: 10px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        text-align: center;
        align-items: center;
    }
} */

/* @media (max-width: 480px) {
    .transactionDetailFooter {
        flex: 1;
        width: 100%;
        max-width: 25em;
        position: fixed;
        padding: 10px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        text-align: center;
        align-items: center;
    }
} */

.transactionDetailFooter .checkStatusButton {
    height: 40px;
    width: 100%;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px;
    border-color: #529F45;
    border-radius: 10px;   
}

.transactionDetailFooter .checkStatusText {
    text-align: center;
    color: #529F45;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 12pt; 
}