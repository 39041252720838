.complaintDetailContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   height: fit-content;
    /* height: 100vh; */
    min-height: 100vh;
    padding-top: 70px;
}

.complaintDetailHeader {
    /* position: absolute;
    width: 520px;
    Z-index: 1090; */
    flex: 1;
    width: 100%;
    height: 50px;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.complaintDetailHeaderTitle {
    display: flex;
    align-items:center; 
    padding-left: 4%;
    padding-bottom: 3%;
    border-bottom: 5px solid #D4D4D4;
}

.complaintDetailBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.complaintDetailBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

/* .complaintDetail {
    margin-top: 40px;
} */

/* @media only screen and (max-width: 1331px) {
    .complaintDetail {
        margin-top: 80px;
    }
} */

.complaintDetailMain {
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 15px;
}

.complaintDetailTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.complaintDetailTitleDate {
    font-size: 15px;
    color: #808080;
    font-family: 'Lato-Bold', sans-serif;
}

.complaintDetailContent {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.complaintDetailContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.complaintDetailContentProduct {
    margin-bottom: 15px;
    font-weight: bold;
    font-family: 'Lato-Black', sans-serif;
}

.complaintDetailContentPrice {
    font-family: 'Lato-Black', sans-serif;
}

.complaintDetailContentQty {
    font-family: 'Lato-Bold', sans-serif;
    color: #808080;
}

.complaintDetailMain table {
    font-family: 'Lato-Black', sans-serif;
}

.complaintDetailMain table tr {
    line-height: 25px;
}

.complaintDetailButton {
  margin-top: 30px;
  height: 45px;
  width: 80%;
  /* background-color: #529f45; */
  background-color: #E07126;
  border-radius: 17px;
  border: none;
}

.complaintDetailButtonContent{
    display: flex;
    justify-content: center;
    align-items: center;
}

.complaintDetailTextButton {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}

.lampiran {
    margin-top: 10px;
}

.lampiran .image {
    width: 100%;
    height: 170px;
    border-radius: 15px;
}

.complaintDetailContentGroupImage {
    align-items: center;
}
