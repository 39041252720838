.ulasanContainer {
  flex: 1;
  display: flex;
  background-color: #fff;
  max-width: 35em;
  min-height: 100vh;
  padding-bottom: 1%;
  flex-direction: column;
}
.ulasanHeader {
  display: flex;
}
.loading {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.ulasanContent {
  flex: 1;
  /* margin-top: 13%; */
  padding-top: 15%;
  padding-left: 4%;
  padding-right: 4%;
}

.viewCariUlasan {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-style: solid;
  font-family: "Lato-Regular"
}
.viewInputcariUlasan {
  border: 0;
}

.imageCariUlasan {
  width: 5%;
  height: 5%;
}

.viewContent {
  margin-top: 4%;
  font-family: sans-serif;
  font-size: 15px;
  padding-left: 1%;
}
.ulasanCard {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  background-color: #f6f6f6;
  border-radius: 10px;
}
.viewTextUlasan {
  margin-left: 3%;
  padding-right: 2%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.viewImgUlasan {
  display: flex;

  width: 90px;
  height: 90px;
  background-color: cadetblue;
  border-radius: 10px;
  /* border-bottom-left-radius: 10px; */
  resize:none;
}
.viewValueUlasan {
  font-size: 12px;
  color: #7d7d7d;
}
.viewUlasanTitle {
  font-weight: 600;
}
.viewUlasanLainnya {
  float: right;
  align-items: flex-end;
  font-size: 12px;
  color: #7d7d7d;
}
a {
    margin-top: 2%;
}
.textTanggal {
  color: #7d7d7d;
  font-size: 14px;
}
