.missionContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    min-height: 100vh;
    background-color: #ffffff;
    font-family: "Lato-Bold", sans-serif;
    height: fit-content;
  }
  .missionContent {
    padding-top: 70px;
    align-items: center;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex: 1;
  }
  .div-detail-mission-card-blue {
    display: flex;
    flex-direction: row;
    background-image: radial-gradient(circle at left bottom, #0f4d95, #267ce0);
    max-width: 90%;
    width: 90%;
    height: fit-content;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 15%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 2%;
  }
  .div-detail-mission-card-green {
    display: flex;
    flex-direction: row;
    background-image: radial-gradient(circle at left bottom, #428733, #6bd05a);
    max-width: 90%;
    width: 90%;
    height: fit-content;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 15%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 2%;
  }
  .div-detail-mission-card-yellow {
    display: flex;
    flex-direction: row;
    background-image: radial-gradient(circle at left bottom, #e07126, #e0c226);
    max-width: 90%;
    width: 90%;
    height: fit-content;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 15%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 2%;
  }
  .card-text-detail-mission {
    margin-top: 5%;
    flex: 2;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-direction: column;
    color: #fff;
    margin-right: 1%;
  }
  .card-desk-detail-mission {
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 10%;
  }
  .card-reward-detail-mission {
    margin-top: 5%;
  }
  .card-desk-detail-mission,
  .card-reward-detail-mission {
    font-size: 20px;
    font-weight: bold;
  }
  .card-date-detail-mission {
    margin-top: 5%;
    display: flex;
    align-items: center;
    font-size: 13px;
  }
  .card-icon-detail-mission {
    flex: 1;
    display: flex;
    /* background-color: rgb(201, 39, 39); */
    justify-content: center;
    /* align-items: center; */
  }
  .icon-detail-mission {
    margin-top: 15%;
    height: 90px;
    width: 90px;
  }
  .icon-available-detail-mission {
    margin-left: 2%;
    margin-right: 3%;
    height: 20px;
    width: 20px;
  }
  .deskripsi-detail-mission {
    width: 90%;
    font-weight: bold;
    font-size: 16px;
    height: fit-content;
    margin-top: 67%;
  }
  .text-deskripsi {
    margin-top: 3%;
    font-weight: normal;
    font-size: 14px;
    text-align: justify;
    color: #7d7d7d;
  }
  .task-detail-mission {
    display: flex;
    flex-direction: row;
    /* background-color: #428733; */
    margin-top: 3%;
    width: 90%;
    border-bottom: 1px solid #E8E8E8;  
    padding-bottom: 5%;
  }
  .div-icon-detail-mission {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
  }
  .icon-dettial-mission {
    height: 60px;
    width: 60px;
  }
  .icon-task-title {
    margin-top: 3%;
  }
  .icon-task-text {
    margin-top: 1%;
  }
  .div-list-task {
    display: flex;
    flex: 1;
    width: 90%;
    color: #529f45;
    padding-top: 5%;
    padding-bottom: 10%;
    flex-direction: column;
  }
  .div-task-card {
    display: flex;
    flex: 1;
    width: fit-content;
    margin-left: 1%;
    margin-top: 2%;
    width: 100%;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;  
  }
  .task-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 10px;
  }
  .task-icon {
    background-repeat: no-repeat;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/images/Task.webp");
    height: 35px;
    width: 35px;
    background-size: contain;
  }
  .task-icon-active {
    background-repeat: no-repeat;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/images/Task-Active.webp");
    height: 35px;
    width: 35px;
    background-size: contain;
  }
  .card-container-detail-mission {
    position: fixed;
    display: flex;
    height: fit-content;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    max-width: 35em;
  }
  
  .task-title {
    margin-bottom: 3px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
  .task-text {
    margin-top: 2px;
    color: #7d7d7d;
    font-size: 16px;
  }
  .hr-detail-mission {
    border-top: 1px solid red;
  }
  