.div-btn-alert-blacklist {
  width: 100%;
  max-width: 35em;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}
.btn-alert-blacklist {
  background-color: #e07126;
  color: #ffffff;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 15px;
  margin-bottom: 2%;
  font-family: "Lato-Bold", sans-serif;
}
.modal-tracking {
  width: 100%;
  max-width: 35em;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.text-title-tracking {
  margin-top: 5%;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.text-alamat-tracking {
  max-width: 80%;
  margin-top: 5%;
  font-family: sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5%;
  color: #529f45;
}
.div-btn-tracking {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-bottom: 5%;
}
.btn-alert-tracking {
  background-color: #e07126;
  color: #ffffff;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 90%;
  border-radius: 10px;
  margin-bottom: 2%;
  font-family: "sans-serif";
  text-align: center;
  font-family: sans-serif;
}

/* alert custom css  */
.container-alert-custom {
  display: flex;
  align-items: center;
  justify-content: center;

}
.div-alert-content {
  background-color: #ffffff;
  border-radius: 10px;
  font-family: "Lato-Bold", sans-serif;
}
.div-alert-custom {
  margin-top: 15%;
  min-width: 290px;
  text-align: center;
}
.div-alert-custom-desc {
  margin-top: 5%;
  text-align: center;
}
.div-btn-alert-custom {
  margin-top: 5%;
  width: 100%;
  max-width: 35em;
  display: flex;
  justify-content: center;
  margin-bottom: 7%;
  padding-right: 7%;
  padding-left: 7%;
  /* background-color: #529F45; */
}
.btn-alert-custom {
  background-color: #e07126;
  color: #ffffff;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
}
.custom-alert-div-icon {
  position: sticky;
  text-align: center;
  background-color: #529f45;
  overflow-x: visible;
  margin-top: -5%;
}
.custom-alert-icon {
  text-align: center;
  background-color: #e07126;
  /* margin-top: -9px; */
}
.div-alert-custom-icon-close{
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 30px;
    left: 76%;
    transform: translate(50%, -40%);
}
.div-alert-custom-icon-icon {
  position: absolute;
  left: 50%;
  height: 65px;
  width: 65px;
  padding: 5px;
  transform: translate(-50%, -40%);
  background-color: #E8E8E8;
  border-radius: 50%;
  border: 5px solid #FFF

}
/* custom alert type image css */
.custom-alert-images-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-alert-type-image {
  display: flex;
  max-width: 270px;
  position: relative;
  overflow: visible;
}
.custom-alert-type-image-image {
  width: 100%;
  height: auto;
  background-size: contain;
  padding: 2%;
}
.icon-close-custom-alert-type-image {
  background: rgba(0, 0, 0, 0.7);
  width: 40px;
  height: 40px;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 10px;
  border-radius: 30px;
}
