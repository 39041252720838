.menuHeader {
    display: flex;
}

.menuHeaderDistributor {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.containerHeader {
    flex-direction: row;
    margin-top: 5px;
    margin-left: 20px;
}

.containerHeaderImage {
    flex-direction: row;
    margin-top: 5px;
}

.listHeader {
    margin-top: 15px;
    display: flex;
    /*CSS3*/
    align-items: center;
    /*Vertical align*/
}

.listHeader .userName {
    font-family: 'Lato-Black', sans-serif;
    font-weight: bold;
}

.listHeader .point {
    font-family: 'Lato-Black', sans-serif;
    font-weight: bold;
    color: #529f45;
}

.listHeader .kredit {
    font-family: 'Lato-Black', sans-serif;
    font-weight: bold;
    color: #529f45;
}

.menuContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
    /* height: fit-content; */
    /* height: 100vh; */
    min-height: 100vh;
    padding-top: 70px;
    padding-bottom: 1%;
    /* min-height: 1200px; */
}

hr {
    height: 1px;
    background-color: #ccc;
    border: none;
}

.subMenu {
    text-decoration: inherit;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    /*CSS3*/
    align-items: center;
    /*Vertical align*/
}

.menuBackContainer {
    border-bottom: 5px solid #D4D4D4;
    padding-left: 4%;
    padding-bottom: 4%;
}

.menuButtonBack {
    display: flex;
    align-items: center;
    /*Vertical align*/
}

.menuBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
}

.menuBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.menuContent {
    padding-left: 3%;
    padding-right: 3%;
}

.menuContentDistributor {
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 12vh;
}

.menuIcon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.menuText {
    margin-top: 2px;
    font-size: 16px;
    color: black;
    font-family: 'Lato-Medium', sans-serif;
}

.menuLogoDistributor {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-left: 20px;
}

.menuLogo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-left: 20px;
}

.subMenuTitle {
    color: red;
    margin-top: 25px;
    font-size: 16px;
    font-weight: 200;
    font-weight: bold;
    font-family: 'Lato-Black', sans-serif;
}

.menuSaldoDistributor {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 10px;
}

.menuTextDistributor {
    margin-top: 2px;
    font-size: 12pt;
    color: black;
    font-family: 'Lato-Medium', sans-serif;
}

.subMenuTitleDistributor {
    color: #529f45;
    margin-top: 25px;
    font-size: 16px;
    font-weight: 200;
    font-weight: bold;
    font-family: 'Lato-Black', sans-serif;
}

.menuProdukDistributor {
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
}

.menuButtonProdukDistributor {
    height: 40px;
    width: 45%;
    background-color: #529f45;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuTextbuttonDistributor {
    font-family: 'Lato-Medium', sans-serif;
    color: #fff;
}

.menuTextVersi {
    font-size: 12pt;
    color: black;
    font-family: 'Lato-Medium', sans-serif;
}