.headerIconArrow {
  width: 20px;
  height: 20px;
}

.deskripsiImageContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  margin-left: 5%;
  max-width: 100%;
  height: 44vh;
  padding-top: 25px;
}

.deskripsiImage {
  display: flex;
  max-width: 90%;
}

.deskripsiChatPosition {
  display: flex;
  align-items: flex-end;
  width: 10%;
  /* margin-left: 1%;
  margin-bottom: 5%; */
}

.deskripsiChatButton {
  display: flex;
  justify-content: center;
  background-color: #529f45;
  width: 100%;
  height: 50px;
  border-style: solid;
  border-width: 0px;
  padding: 4px;
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
}

.deskripsiChatImage {
  display: flex;
  align-self: center;
  width: 60%;
}

.deskripsiInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  margin-top: 20px;
}

.deskripsiInfoTopContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 88%;
  height: 30px;
  margin: auto;
}

.deskripsiInfoCenterContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 88%;
  height: 30px;
  margin: auto;
  margin-top: 10px;
}

.deskripsiInfoBottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 50%;
  height: 30px;
  margin: auto;
}

.deskripsiPrice {
  display: flex;
  flex: 1;
  /* font-family: 'Lato-Regular'; */
  font-family: "Lato-Regular", sans-serif;
  color: #529f45;
  font-size: 16pt;
}

.deskripsiPromo {
  display: flex;
  padding: 4px 10px;
  background-color: #b4e5ed;
  justify-content: center;
  align-items: center;
  background-color: #b4e5ed;
  margin-left: 3%;
}

.deskripsiPromoText {
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  color: #17a2b8;
  font-size: 12pt;
  font-weight: bold;
}

.deskripsiInfoImage {
  margin-left: 1%;
  max-height: 30px;
}

.deskripsiProductName {
  /* font-family: 'Lato-Regular'; */
  font-family: "Lato-Regular", sans-serif;
  color: #000000;
  font-size: 15pt;
}

.deskripsiWishlistContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: #fff;
  border-radius: 10px;
  min-width: 19%;
  min-height: 35px;
  margin-right: 3%;
  padding-right: 3%;
  padding-left: 3%;
}

.deskripsiLoveImage {
  max-height: 20px;
}

.deskripsiRatingText {
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  color: #000000;
  font-size: 12pt;
  margin-left: 5%;
}

.deskripsiRating {
  margin-top: -4px;
}

.deskripsiDetailContainer {
  width: 90%;
  margin: auto;
}

.deskripsiDetailTitle {
  color: #000000;
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  font-size: 14pt;
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
}

.deskripsiDetailTextContainer {
  display: flex;
  width: 100%;
  margin-top: 10px;
  color: #7c7c7c;
}

.deskripsiDetailText {
  text-align: left;
  width: 50%;
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
}

.deskripsiDetailData {
  text-align: left;
  width: 40%;
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  color: #529f45;
}

.deskripsiDetailInfoContainer {
  width: 100%;
  margin: auto;
  /* margin-top: 30px; */
  /* padding-bottom: 150px; */
}

.deskripsiDetailInfoText {
  text-align: left;
  color: #000000;
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  margin-top: 2%;
}

.deskripsiNavbar {
  flex: 1;
  width: 100%;
  height: 70px;
  max-width: 35em;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1090;
}

/* @media (max-width: 780px) {
    .deskripsiNavbar {
        flex: 1;
        width: 100%;
        height: 70px;
        max-width: 30em;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        padding-left: 50px;
        padding-right: 50px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 5px solid #bcbc;
        z-index: 1090;
    }
} */

/* @media (max-width: 480px) {
    .deskripsiNavbar {
        flex: 1;
        max-width: 25em;
        width: 100%;
        bottom: 0%;
        left: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        -webkit-transform: translate(-50%,0%);
           -moz-transform: translate(-50%,0%);
            -ms-transform: translate(-50%,0%);
             -o-transform: translate(-50%,0%);
                transform: translate(-50%,0%);
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 5px solid #bcbc;
        z-index: 1090;
    }
} */

.deskripsiNavbarButtonLeft {
  width: 40%;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  border-color: #529f45;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deskripsiNavbarButtonRight {
  width: 40%;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  background-color: #529f45;
  display: flex;
  align-items: center;
  justify-content: left;
}

.deskripsiNavbarTextLeft {
  text-align: center;
  color: #529f45;
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
}

.deskripsiNavbarTextRight {
  text-align: left;
  color: #fff;
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deskripsiNavbarImage {
  max-height: 20px;
}

.deskripsiViewPromo {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.deskripsiSelect {
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 3px;
  height: 35px;
  text-align: left;
  max-width: 90%;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
}

.notifProductProdukDeskripsi {
  padding-top: 20px;
  border-radius: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 11vh;
}

.produkDeskripsiStorePartnerName {
  font-family: "Lato-Medium", sans-serif;
  font-size: 12pt;
  color: #FFF;
}

.produkDeskripsiDistributorDeskripsiImage {
  display: flex;
  max-width: 90%;
  height: 40%;
}

.produkDeskripsiDistributorImageContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  max-width: 100%;
  height: 44vh;
  padding-top: 25px;
}

.produkDeskripsiDistributorDiskusi {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.produkDeskripsiDistributorDiskusi img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.produkDeskripsiDistributorDiskusiNamaUser {
  font-family: "Lato-Medium", sans-serif;
  font-size: 13pt;
  margin-right: 5px;
}

.produkDeskripsiDistributorDiskusiNamaToko {
  font-family: "Lato-Medium", sans-serif;
  font-size: 13pt;
  margin-right: 5px;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 5px 6px;
}

.produkDeskripsiDistributorDiskusiTanggal {
  font-family: "Lato-Medium", sans-serif;
  font-size: 10pt;
  color: #BCBCBC;
}

.produkDeskripsiDistributorDiskusiMessage {
  font-family: "Lato-Regular", sans-serif;
  font-size: 13pt;
}

.produkDeskripsiDistributorDiskusiMessageLainnya {
  font-family: "Lato-Regular", sans-serif;
  font-size: 10pt;
  color: #BCBCBC;
}

.produkDeskripsiDistributorDiskusiContainerReply {
  border-left-style: solid;
  border-color: #BCBCBC;
  border-width: 3px;
  padding: 0px 11px;
  margin-left: 25px;
  margin-bottom: 5px;
}

.produkDeskripsiDistributorDiskusiContainerMessage {
  border-bottom-style: solid;
  border-color: #BCBCBC;
  border-width: 1px;
  padding: 3px 0px;
}

@media (max-width: 480px) {
  .deskripsiNavbarTextRight {
    font-size: 11pt;
  }
}
.varian-deskripsi-produk {
  margin-top: 5px;
  height: 25px;
  text-align: justify;
  font-family: "Lato-Bold", sans-serif;
  font-size: 11pt;
  margin-left: 5%;
}
.varian-horizontal-scroll {
  display: flex;
  width: 90%;
  /* background-color: #17a2b8; */
  height: fit-content;
  flex-direction: row;
  align-items: center;
  /* padding: 10px 0px 10px 0px; */
  margin: 0 0 0 5%;
  overflow-x: scroll;
  object-fit: cover;
}
.varian-horizontal-scroll::-webkit-scrollbar {
  display: none;
}
.varian-images-content {
  max-height: 50px;
  max-width: auto;
  margin-right: 5px;
  text-align: center;
  margin: 0 10px 0 5px;
}