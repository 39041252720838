.listSubscribeContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    padding-top: 70px;
    /* min-height: 1200px; */
}

.listSubscribeBackContainer {
    border-bottom: 5px solid #D4D4D4;
    padding-left: 4%;
    padding-bottom: 4%;
}

.listSubscribeButtonBack {
    display: flex;
    align-items:center;/*Vertical align*/
}

.listSubscribeBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.listSubscribeBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.listSubscribeImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
    border-radius: 10px;
}

.listSubscribeInform .listSubscribeProductName {
    margin-bottom: 15px;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}

.listSubscribePriceView {
    display: flex;
}

.listSubscribeInform .listSubscribeProductStatus {
    margin-top: 15px;
    color: #5d5d5d;
    font-family: 'Lato-Bold', sans-serif;
}

.listSubscribeInform .listSubscribeProductPrice {
    margin-top: 15px;
    color: #529f45;
    font-family: 'Lato-Bold', sans-serif;
}

.listSubscribeInform .listHalf {
    margin-top: 15px;
    margin-left: 5px;
    color: #17a2b8;
    font-family: 'Lato-Bold', sans-serif;
}

.listSubscribeMain {
    border: none;
    padding: 15px;
    background-color: #f4f4f4;
    margin-top: 10px;
    padding-left: 3%;
    display: flex;
    align-items:center;/*Vertical align*/
}

.listSubscribeButton {
    margin-left: auto;
}

.listSubscribeButton .detail .button{
    /* margin-top: 30px; */
    height: 40px;
    width: 60px;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
}

.listSubscribeButton .detail .text{
    color: #fff;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}

.listSubscribeButton .hapus .button{
    margin-top: 15px;
    height: 40px;
    width: 60px;
    background-color: red;
    border-radius: 10px;
    border: none;
}

.listSubscribeButton .hapus .text{
    color: #fff;
    font-weight: bold;
    font-family: 'Lato-Bold', sans-serif;
}

