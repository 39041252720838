.container-splash {
  flex: 1;
  width: 100%;
  /* height: fit-content; */
  min-height: 100vh;
  max-width: 35em;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  z-index: 1500;
  display: flex;
  font-family: "Lato-Bold", sans-serif;
  flex-direction: column;
}
.content-splash {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.images-splash {
  width: 70%;
  height: auto;
}
.text-title-splash {
  margin-top: 5%;
  text-align: center;
  font-weight: bold;
}
.text-isi-splash {
  margin-top: 3%;
  text-align: center;
}
.btn-mulai-misi {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  margin-bottom: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.text-btn-mulai-misi{
  background-color: #529f45;
  width: 80%;
  text-align: center;
  color: #ffffff;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  border-radius: 10px;
  cursor: default;
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
  border: none;
}
