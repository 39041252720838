.notificationContainer {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  height: max-content;
  background-color: #fff;
  padding-top: 20%;
  padding-bottom: 10%;
  /* min-height: 1200px; */
}

.notificationMain {
  /* height: fit-content; */
  height: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  padding: 15px;
}

.dot {
  height: 35px;
  width: 35px;
  /* background-color: #bbb; */
  background-color: #49852e;
  border-radius: 50%;
  display: flex;
  margin-left: auto;
  font-size: 15px;
  justify-content: center;
}

.dotNumber {
  margin-top: 7px;
  color: white;
  font-family: "Lato-Bold", sans-serif;
}

.subNotif {
  z-index: inherit;
  text-decoration: inherit;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  /*CSS3*/
  align-items: center;
  /*Vertical align*/

}

.notifIcon {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.notifText {
  font-size: 18px;
  font-weight: 500;
  color: black;
  font-family: "Lato-Bold", sans-serif;
}

.notifProduct {
  padding-top: 20px;
  border-radius: 20px;
}

.notifProductTitle {
  font-family: "Lato-Black", sans-serif;
  font-weight: bold;
  font-size: 12pt;
  text-align: left;
  margin-left: 5px;
}

.notifProductContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: -15px;
  width: 100%;
  overflow-x: scroll;
}

.notifProductContainer::-webkit-scrollbar {
  display: none;
}

.notifProductContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.notifProductRecomenContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  max-width: 35em;
}

.notifProductRecomenItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  /* height: fit-content; */
  height: 100vh;
  width: 30%;
  min-width: 150px;
  margin: 2px 8px 15px 8px;
  padding-bottom: 25px;
}

.notifProductItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  /* height: fit-content; */
  height: 100vh;
  width: 32%;
  min-width: 150px;
  margin: 2px 8px 15px 8px;
  padding-bottom: 25px;
}

.notifProductImage {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  margin: 5px;
  margin-top: 25px;
  max-width: 90%;
  max-height: 90%;
}

.notifProductItemTitle {
  margin: 5px 10px;
  font-family: "Lato-Regular", sans-serif;
  text-align: left;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notifProductItemPrice {
  font-family: "Lato-Regular", sans-serif;
  color: #529f45;
  display: flex;
  font-size: 16pt;
  margin: 0px 10px;
}

.notifProductItemRating {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notifProductItemRatingValue {
  font-family: "Lato-Bold";
  margin-left: 5%;
  text-align: left;
}

.notifProductItemButton {
  margin: auto;
  margin-top: 5px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #529f45;
  border-radius: 10px;
  width: 80%;
  height: 30px;
}

.notifProductItemButtonBeli {
  text-align: center;
  color: #529f45;
  font-family: "Lato-Bold";
  font-size: 12pt;
}

.notifProductItemPromo {
  display: flex;
  position: absolute;
  margin: 7px;
  padding: 4px 10px;
  background-color: #b4e5ed;
  justify-content: center;
  align-items: center;
}

/* .viewDate {
  flex: 1;
  float: right;
} */
.notifTextDate {
  flex: 1;
  direction: rtl;
  font-size: 15px;
  font-weight: 200;
  color: #A4A4A4;
  font-family: "Lato-Bold", sans-serif;
  width: fit-content;
}

.viewTitle {
  flex: 1;
  padding-top: 1.5%;
}

.divListChat {
  display: flex;
  flex: 1;
  background: "#7d7d7d";
}

.notifTextMes {
  font-size: 15px;
  font-weight: 200;
  color: #A4A4A4;
  font-family: "Lato-Bold", sans-serif;
}