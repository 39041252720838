.headerContainerMenu .headerMenuLogo {
  width: 30px;
  height: 30px;
}

.headerContainerMenu .headerMenuLonceng {
  width: 30px;
  height: 30px;
}

.confirmationOrderTopContainer {
  /* width: 35em; */
  max-width: 35em;
 /* height: fit-content; */
    /* height: 100vh; */
  /* min-height: 1200px; */
  padding: 5px 5%;
  border-bottom: 2px solid #bcbc;
  padding-top: 25pt;
  padding-bottom: 10vh;
}

.confirmationOrderTopTitle {
  font-family: "Lato-Bold", sans-serif;
  font-size: 14pt;
  color: black;
  text-align: left;
}

.confirmationOrderTopCard {
  margin-top: 20px;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #bcbc;
  border-radius: 5px;
  width: 100%;
 /* height: fit-content; */
    /* height: 100vh; */
  padding: 5px;
}

.confirmationOrderTopFirst {
  display: flex;
  flex-direction: row;
}

.confirmationOrderTopCardLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
}

.confirmationOrderTopCardRight {
  width: 80%;
  margin-left: 2%;
}

.confirmationOrderTopCardLeftImage {
  margin: 0px 5%;
}

.confirmationOrderTopCardRightTitle {
  margin-top: 20px;
  text-align: left;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  font-weight: 500;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.confirmationOrderTopCardRightText {
  text-align: left;
  margin-top: 5px;
  font-family: "Lato-Bold", sans-serif;
  color: #777;
  font-size: 12pt;
}

.confirmationOrderTopCardRightTextHalf {
  text-align: left;
  margin-top: 5px;
  font-family: "Lato-Bold", sans-serif;
  margin-left: 5px;
  color: #17a2b8;
  font-size: 12pt;
}

.confirmationOrderTopCardTotal {
  text-align: left;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  font-weight: 500;
  color: black;
  margin: 5px 0px;
}

.confirmationOrderCenterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 35em;
 /* height: fit-content; */
    /* height: 100vh; */
  /* min-height: 1200px; */
  padding: 20px 5%;
  border-bottom: 1px solid #bcbc;
}

.confirmationOrderCenterTitle {
  font-family: "Lato-Bold", sans-serif;
  font-size: 14pt;
  color: black;
  font-weight: 600;
}

.confirmationOrderCenterText {
  font-family: "Lato-Bold";
  font-size: 14pt;
  color: #529f45;
}

.confirmationOrderBottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 35em;
 /* height: fit-content; */
    /* height: 100vh; */
  /* min-height: 1200px; */
  padding: 20px 5%;
}

.confirmationOrderBottomFirstTitle {
  width: 100%;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  color: grey;
}

.confirmationOrderBottomSecond {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.confirmationOrderBottomSecondButton {
  background-color: #529f45;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 45%;
 /* height: fit-content; */
    /* height: 100vh; */
  padding: 10px;
  border-style: solid;
  border-width: 0px;
  border-radius: 15px;
}

.confirmationOrderBottomSecondButtonText {
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
  color: #fff;
  font-size: 13pt;
}

@media (max-width: 480px){
  .confirmationOrderBottomSecond {
    flex-direction: column;
  }
  .confirmationOrderBottomSecondButton {
    width: 100%;
    margin-bottom: 10px;
  }
}
