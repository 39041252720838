.subscribContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
 /* height: fit-content; */
    height: 100vh;
  padding-top: 70px;
  /* padding-top: 8%; */
  /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .subscribContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

/* @media (max-width: 480px) {
    .subscribContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

.subscribBackContainer {
  border-bottom: 5px solid #d4d4d4;
  padding-left: 4%;
  padding-bottom: 4%;
}

.subscribButtonBack {
  display: flex;
  align-items: center; /*Vertical align*/
}

.subscribBack {
  width: 13px;
  height: 13px;
  margin-right: 13px;
}

.subscribBackText {
  margin-left: 10px;
  font-weight: bold;
  font-size: 17px;
}

.subscribContent {
  padding-left: 3%;
}

/* .subscribe {
    margin-top: 35px;
} */

/* @media only screen and (max-width: 1331px) {
    .subscribe {
        margin-top: 80px;
    }
} */

.subscribeMain {
  border: none;
  padding: 15px;
  background-color: #f4f4f4;;
  margin-top: 10px;
}

.subscribeContentHeader {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.subscribeContentHeader .subscribeContentStatus .subscribeTime {
  font-family: "Lato-Bold", sans-serif;
}

.subscribeContentHeader .subscribeContentStatus .subscribeProductQty {
  font-family: "Lato-Bold", sans-serif;
}

.subscribeTime {
  font-weight: bold;
}

.subscribeProductQty {
  margin-top: 5px;
  color: #5d5d5d;
}

.subscribeContentHeaderDetail {
  margin-left: auto;
}

.subscribeButtonDetail {
  height: 40px;
  width: 50px;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.subscribeTextDetail {
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-weight: bold;
}

.subscribeContentMain {
  padding-top: 3%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.subscribeContentMain::-webkit-scrollbar {
  display: none;
}

.subscribeContentMain {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.subscribeContentImage {
  height: 110px;
  width: 110px;
  margin-right: 10px;
}
