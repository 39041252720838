.pointContainer {
    max-width: 35em;
    /* width: 35em; */
   /* height: fit-content; */
    height: fit-content;
    background-color: #fff;
    /* min-height: 1200px; */
    padding-bottom: 5%;
    position: sticky;
}

.pointHeader {
    flex: 1;
    width: 100%;
   /* height: fit-content; */
    /* height: 100vh; */
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
    padding-top: 20%;
}

.pointHeaderTitle {
    display: flex;
    align-items:center; 
    border-bottom: 5px solid #D4D4D4;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 4%;
}

.pointBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.pointBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.pointHeaderCard {
    /* margin-top: 15px; */
    padding: 3%;
    width: 92%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-left: 4%;
    margin-right: 4%;
    text-align: left;
}

.pointHeaderMain {
    margin-bottom: 5px;
    font-family: 'Lato-Regular', sans-serif;
}

.pointHeaderFooter {
    text-align: left;
    padding-left: 5%;
    padding-top: 4%;
    font-weight: bold;
    font-family: 'Lato-Regular', sans-serif;
}

.pointListMain {
    /* padding-top: 15px; */
    padding-left: 5%;
    padding-right: 5%;
}

.pointListCard {
    margin-top: 15px;
    border-radius: 10px;
    border-width: 1px;
    background-color: #f9f9f9;
    border-color: #D3D3D3;
    border-style: solid;
    position: relative;
   /* height: fit-content; */
    /* height: 100vh; */
    /* padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px; */
    padding: 8px;
}

.pointListHeader {
    display: flex;
    align-items: center;
    font-size:smaller;
    font-family: 'Lato-Regular', sans-serif;
}

.pointListContent {
    text-align: left;
    margin-top: 7px;
    font-size:small;
    margin-bottom: 10px;
    font-family: 'Lato-Regular', sans-serif;
}

.pointListContentHeader {
    margin-top: 5px;
    width: 90%;
}

.pointListContent .middle {
    display: flex;
    align-items:center; 
}

.pointListContent .point {
    margin-left: auto;
    font-size: 18px;
    font-weight: bold;
    width: 35%;
    text-align: right;
}

.pointListContentMain {
    margin-top: 5px;
}

.pointListIcon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
} 

.pointListRight {
    margin-left: auto;
    color: #808080;
}
