.headerSeenContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 70%;
    padding: 20px;
}

.headerSeenText {
    font-family: "Lato-Bold", sans-serif;
    color: grey;
}

.chatNavbar {
    flex: 1;
    width: 100%;
    height: 70px;
    max-width: 35em;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 5px solid #bcbc;
    z-index: 1090;
}

/* @media (max-width: 780px) {
    .chatNavbar {
        flex: 1;
        width: 100%;
        height: 70px;
        max-width: 30em;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        padding-left: 50px;
        padding-right: 50px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 5px solid #bcbc;
        z-index: 1090;
    }
} */

/* @media (max-width: 480px) {
    .chatNavbar {
        flex: 1;
        max-width: 25em;
        width: 100%;
        bottom: 0%;
        left: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        -webkit-transform: translate(-50%,0%);
           -moz-transform: translate(-50%,0%);
            -ms-transform: translate(-50%,0%);
             -o-transform: translate(-50%,0%);
                transform: translate(-50%,0%);
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 5px solid #bcbc;
        z-index: 1090;
    }
} */

.chatNavbarInput {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #bcbc;
    border-radius: 10px;
    width: 80%;
    height: 60%;
    padding: 0px 5%;
}

.chatNavbarIcon {
    min-width: 16%;
    border-style: solid;
    border-width: 0px;
    border-radius: 50%;
    height: 60%;
    background-color: #529F45;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatNavbarIconLogo {
    height: 20px;
}

.chatNavbarInputText {
    text-align: left;
    border-color: #bcbc;
    border-style: solid;
    border: 0;  
    top: 30%;
    font-family: "Lato-Bold", sans-serif;
    font-size: 16pt;
}

.chatHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 35em;
    height: 50px;
}

.chatHeaderText {
    font-family: "Lato-Bold", sans-serif;
    color: grey;
}

.chatContent {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-bottom: 200px;
}

.chatContentLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 35em;
   /* height: fit-content; */
    /* height: 100vh; */
    margin-top: 5px;
}

.chatContentRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    max-width: 35em;
   /* height: fit-content; */
    /* height: 100vh; */
    margin-top: 5px;
}

.chatContentRightBubble {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    width: 30%;
    background-color: #7EDC6E;
    margin-right: 5%;
    padding: 5px 2%;
    border-radius: 10px;
}
.chatContentRightBubble-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    width: 60%;
    background-color: #7EDC6E;
    padding: 5px 2%;
    border-radius: 10px;
    margin: 20px 5% 20px 0;
}
.chatContentRightBubble-image-div-image {
    display: flex;
    width: 100%;
    max-width: 450px;
    height: 10%;
    background-color: #7EDC6E;
    border-radius: 3px;
    padding: 5px;
}
.chatContentRightBubble-image-text{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    justify-content: center;
    font-family: "Lato-Regular", sans-serif;
    font-size: 10pt;
}
.chatContentRightBubble {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    width: 30%;
    background-color: #7EDC6E;
    margin-right: 5%;
    padding: 5px 2%;
    border-radius: 10px;
}

.chatContentLeftBubble {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    height: 100%;
    background-color: #E8E8E8;
    margin-left: 4%;
    padding: 8px 5%;
    border-radius: 10px;
}

.chatContentLeftProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    width: 40px;
    height: 40px;
    background-color: grey;
    border-radius: 50%;
}

.chatContentLeftProfileText {
    color: #fff;
    font-family: "Lato-Bold", sans-serif;
    font-size: 14pt;
}

.chatContentRightText {
    color: #000000;
    font-family: "Lato-Bold", sans-serif;
    font-size: 16pt;
}

.chatContentLeftText {
    color: #000000;
    font-family: "Lato-Bold", sans-serif;
    font-size: 16pt;
}

.chatContentRightTime {
    margin-top: 5px;
    color: white;
    font-family: "Lato-Bold", sans-serif;
    font-size: 10pt;
}

.chatContentLeftTime {
    margin-top: 5px;
    color: grey;
    font-family: "Lato-Bold", sans-serif;
    font-size: 10pt;
}

.chatScrollUp {
    transform: rotate(180deg);
    height: 20px;
    width: 20px;
    align-self: center;
}

.chatContentItemLeft {
    width: 200px;
    padding: 8px;
    display: flex;
    border-radius: 5px;
    border-style: solid;
    border-color: #ddd;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
}

.chatContentItemLeft img {
    width: 37%;
    height: 100%;
    margin-right: 5px;
    border-radius: 5px;
}

.chatContentItemText {
    color: 000;
    font-family: "Lato-Regular", sans-serif;
    font-size: 10pt;
}

.chat-content-item {
    bottom:85px;
    position: fixed;
    display: flex;
    width: 70%;
    max-width: 450px;
    margin-left: 3%;
    height: 10%;
    background-color: #FFF;
    border-radius: 3px;
    border: 3px solid #E8E8E8;
    padding: 5px;
}
.chat-content-item-image{
    height: 100% auto;
}
.chat-content-item-text{
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    justify-content: center;
    font-family: "Lato-Regular", sans-serif;
    font-size: 10pt;
}
.chat-content-item-icon{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}