.container {
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
  /* height: 1000px; */
  padding-top: 40%;
  /* min-height: 1200px; */
}

.logo {
  width: 200px;
  height: 200px;
}

.judul {
  font-size: 32px;
  font-weight: bold;
  color: #529f45;
}

.iconOtp {
  height: 20px;
  padding-left: 20px;
}

.containerInputLogin {
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 5px 5px 5px #aaaaaa;
  border-color: #D3D3D3;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.textInputLogin {
  border-color: #bcbc;
  border-style: solid;
  margin-bottom: 5px;
  border: 0;  
  position: absolute;
  top: 30%;
  margin-left: -80px;
}

textarea:focus, input:focus{
  outline: none;
}

.buttonOK {
  margin-top: 30px;
  height: 40px;
  width: 60%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.textButtonOK {
    color: #fff
}

.link {
  margin-top: 20px;
}

.linkResendOtp {
  margin-right: 45px;  
  color: rgba(65, 70, 65, 0.8); /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  font-size: 0.9rem;
}

.linkWrongNumber {
  margin-left: 40px;
  color: rgba(65, 70, 65, 0.8); /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  font-size: 0.9rem;
}
