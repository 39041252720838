* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  /* width: 35em; */
  max-width: 35em;
 /* height: fit-content; */
    height: 100vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  color: #fff;
  margin: 1rem 0;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
  font-weight: 600;
}

.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #000;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
  font-family: "Lato-Bold", sans-serif;
  font-size: 10pt;
  font-weight: 600;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}

/* .loading {
    flex: 1;
    display: flex;
    background: transparent;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
