._waitingRatingContainer {
  max-width: 35em;
  min-height: 100vh;
  background-color: #FFF;
}

.waitingRatingCard {
  display: flex;
  flex-direction: row;
  width: 100%;
 /* height: fit-content; */
    /* height: 100vh; */
  padding: 15px 3%;
  border-bottom: 1px solid #bcbc;
}

.waitingRatingCardLeftContainer {
  /* width: 30%; */
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.waitingRatingCardRightContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.waitingRatingCardLeftImage {
  margin: 0px 5%;
  height: 120px;
  width: 120px;
}

.waitingRatingCardLeftText {
  margin-top: 5px;
  font-family: "Lato-Bold", sans-serif;
  color: #777;
  font-size: 12pt;
  width: 100px;
  margin-left: 15px;
}

.waitingRatingCardRightTitle {
  margin-top: 20px;
  font-family: "Lato-Bold", sans-serif;
  font-size: 15pt;
  font-weight: 600;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.waitingRatingCardRightText {
  margin-top: 5px;
  font-family: "Robot-Medium", sans-serif;
  font-size: 13pt;
  font-weight: 500;
  color: grey;
}

.waitingRatingCardRightTextHalf {
  margin-top: 5px;
  font-family: "Robot-Medium", sans-serif;
  font-size: 13pt;
  font-weight: 500;
  margin-left: 5px;
  color: #17a2b8;
}

.waitingRatingCardRightStarContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.waitingRatingCardRightStar {
  width: 100%;
}
.headerReview {
  padding-top:20%;
  background-color: white;
}