

.headerIconArrow {
  width: 20px;
  height: 20px;
}

.subscribeDetailImageContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  margin-left: 5%;
  max-width: 100%;
  height: 500px;
}

.subscribeDetailImage {
  display: flex;
  max-width: 90%;
}

.subscribeDetailChatPosition {
  display: flex;
  align-items: flex-end;
  width: 10%;
  margin-left: 1%;
  margin-bottom: 5%;
}

.subscribeDetailChatButton {
  display: flex;
  justify-content: center;
  background-color: #529f45;
  width: 100%;
  height: 40px;
  border-style: solid;
  border-width: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.subscribeDetailChatImage {
  display: flex;
  align-self: center;
  width: 60%;
}

.subscribeDetailDetailContainer {
  width: 90%;
  margin: auto;
}

.subscribeDetailDetailTitle {
  color: #000000;
  font-family: "Lato-Bold", sans-serif;
  font-size: 14pt;
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
}

.subscribeDetailDetailTextContainer {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.subscribeDetailDetailTextContainer .inputBorder {
  margin-left: 5%;
  width: 85%;
  height: 30px;
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 2%;
}

.subscribeDetailDetailContainer .inputBorderCatatan {
  /* margin-left: 5%; */
  max-width: 96%;
  margin-top: 2%;
  height: 80px;
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 2%;
}

.subscribeDetailDetailTextContainer .inputBorder select {
  border: none;
  background-color: #fff;
  width: 100%;
}

.subscribeEditQtyContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 36%;
}

.subscribeEditItemTrash {
  max-height: 50%;
}

.subscribeEditItemQtyMinus {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 18%;
  height: 25px;
  margin-left: 0.5em;
}

.subscribeEditItemQtyMinusImage {
  width: 40%;
  height: 40%;
}

.subscribeEditItemQtyPlus {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 18%;
  height: 25px;
}

.subscribeEditItemQtyPlusImage {
  width: 40%;
  height: 40%;
}

.subscribeEditItemQtyInput {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  color: black;
  font-family: "Lato-Regular", sans-serif;
  text-align: center;
  height: 25px;
}

.subscribeDetailBottom button {
  margin-right: 2%;
  padding: 3%;
  border-radius: 8px;
  border: none;
}

.subscribeDetailBottom button .text {
  color: #fff;
}

.subscribeDetailBottom .btnUbah {
  background-color: #529f45;
  padding: 10px;
}

.subscribeDetailBottom .btnChange {
  background-color: #ff781f;
}

.subscribeEditNavbar {
  flex: 1;
  width: 100%;
  height: 70px;
  max-width: 35em;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1090;
}

/* @media (max-width: 780px) {
    .subscribeEditNavbar {
        flex: 1;
        width: 100%;
        height: 70px;
        max-width: 30em;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        z-index: 1090;
    }
} */

/* @media (max-width: 480px) {
    .subscribeEditNavbar {
        flex: 1;
        width: 100%;
        height: 70px;
        max-width: 25em;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        z-index: 1090;
    }
} */

.subscribeEditNavbarButtonLeft {
  width: 43%;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  border-color: #ff781f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribeEditNavbarButtonRight {
  width: 43%;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #529f45;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribeEditNavbarTextLeft {
  text-align: center;
  color: #ff781f;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
}

.subscribeEditNavbarTextRight {
  text-align: center;
  /* color: #fff; */
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
  display: flex;
  justify-content: center;
}

.subscribeEditNavbarImage {
  max-height: 20px;
}

.subscribeEditDetailText {
  text-align: left;
  width: 60%;
  font-family: "Lato-Bold", sans-serif;
  font-size: 13pt;
}
