.loginContainer {
  background-color: #fff;
  max-width: 35em;
  /* padding-top: 40%; */
  /* width: 35em; */
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 1200px; */
}

.loginLogo {
  width: 200px;
  height: 200px;
}

.loginJudul {
  font-size: 32px;
  font-weight: bold;
  font-family: "Lato-Bold", sans-serif;
  color: #529f45;
}

.loginIconUser {
  height: 20px;
  padding-left: 20px;
}

.loginContainerInput {
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 3px 3px 3px #aaaaaa;
  border-color: #e8e8e8;
  border-style: solid;
  position: relative;
  height: 40px;
  width: 60%;
  padding-top: 7px;
}

.loginTextInput {
  border-color: #bcbc;
  border-style: solid;
  border: 0;
  font-family: "Lato-Bold", sans-serif;
  font-size: 16px;
  width: 70%;
  padding-left: 20px;
}

textarea:focus,
input:focus {
  outline: none;
}

.loginButtonOK {
  margin-top: 30px;
  height: 40px;
  width: 60%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.loginTextButtonOK {
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
}

.loginLink {
  margin-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
}

.loginLinkRegister {
  margin-right: 25%;
  color: rgba(65, 70, 65, 0.8); /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  font-size: 0.9rem;
  font-family: "Lato-Bold", sans-serif;
}

.loginLinkForgetPassword {
  margin-left: 10%;
  color: rgba(65, 70, 65, 0.8); /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  font-size: 0.9rem;
  font-family: "Lato-Bold", sans-serif;
}

.loadingGet {
  flex: 1 1;
  display: flex;
  /* background-color: rgba(52, 52, 52, 0.2); */
  background-color: white;
  justify-content: center;
  /* align-items: center; */
  /* min-height: 1200px; */
  position: absolute;
  /* width: 35em; */
  max-width: 35em;
  top: 0;
  padding-top: 50%;
}

.posisiLoading {
  flex: 1 1;
  display: flex;
  /* background-color: rgba(52, 52, 52, 0.2); */
  background-color: white;
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: 35em;
  left: 20em;
  top: 50%;
}

.NoData {
  background-color: white;
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* align-self: center; */
  position: relative;
  left: 25%;
  max-width: 35em;
  /* padding-top: 20%; */
}

.inputSearchWA {
  width: 47%;
  height: 50px;
  background-color: #529f45;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  .inputSearchWA {
    width: 85%;
  }
}

.select-search {
  width: 300px;
  position: relative;
  font-family: "Lato-Bold", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}
.registerEye{
  width: 10%;
  height: auto;
}
/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #529f45;
  color: #FFF;
  /* border: 1px solid transparent; */
  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
  border-radius: 3px;
  outline: none;
  font-family: "Lato-Bold", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  appearance:none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #529f45;
  color: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  min-height: fit-content;
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #529f45;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.select-search__input::-webkit-input-placeholder {
  color: #FFF
}
