.cartContainer {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  min-height: 100vh;
  padding-top: 40px;
  background-color: #fff;
  /* min-height: 1200px; */
}

.cartContent {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  /* height: 100vh; */
  min-height: 100vh;
  background-color: #fff;
  margin-top: 50px;
  padding-bottom: 90px
}

.cartTopCardContainer {
  display: flex;
  justify-content: center;
  background-color: #529f45;
  border-radius: 10px;
  border-color: #f9f9f9;
  padding: 10px;
  max-width: 32em;
  width: 90vw;
}

.cartTopCardContainerKredit {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
  height: 7vh;
  background-color: #e07126;
  border-radius: 10px;
  border-color: #f9f9f9;
  align-items: center;
  padding: 10px;
}

.cartTopCardText {
  font-family: "Lato-Bold", sans-serif;
  color: #fff;
  text-align: left;
  font-size: 11pt;
  margin: 0px 2% 0px 2%;
}

.cartCheckBoxText {
  font-family: "Lato-Bold", sans-serif;
}

.cartProdukContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  /* height: fit-content; */
  height: 100vh;
  max-width: 35em;
}

.cartCheckBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 137vw;
  max-width: 32em;
  height: 20px;
  margin: 5px 0px 7px 5%;
}
.shop-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5% 0 5%;
  padding: 3% 0 3% 7%;
  font-weight: 500;
}
.cartCheckBoxImage {
  max-width: 20px;
  max-height: 20px;
  margin-right: 2%;
}

.cartProduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-bottom: 15px; */
  /* padding-top: 12px; */
}

.cartProductContainer {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 10px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  width: 90%;
  max-width: 35em;
  margin: 10px auto;
  height: 220px;
  padding: 10px;
}

.cartProductCheckBox {
  width: 15%;
  margin-top: 10%;
  margin-left: 17px;
}

.cartProductItem {
  width: 90%;
}

.cartProductItemTop {
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  height: 70%;
}

.cartProductItemBottom {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30%;
  font-family: "Lato-Bold", sans-serif;
}

.cartProductItemText {
  padding-left: 3%;
  padding-top: 1%;
  padding-bottom: 10px;
}

.cartProductItemImage {
  margin: 2%;
  width: 30%;
  height: auto;
}

.cartProductItemName {
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  text-align: left;
  font-size: 12pt;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cartProductItemPrice {
  /* font-family: 'Lato-Bold'; */
  font-family: "Lato-Bold", sans-serif;
  color: #096a3a;
  font-size: 16pt;
}

.cartProductItemTrashContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35%;
  width: 80%;
}

.cartProductItemTrash {
  max-height: 50%;
}

.cartProductItemQtyMinus {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 20%;
  height: 50%;
  margin-left: 15%;
}

.cartProductItemQtyMinusImage {
  width: 40%;
  height: 40%;
}

.cartProductItemQtyPlus {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 20%;
  height: 50%;
}

.cartProductItemQtyPlusImage {
  width: 40%;
  height: 40%;
}

.cartProductItemQtyInput {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  color: black;
  font-family: "Lato-Regular", sans-serif;
  text-align: center;
  width: 40%;
  height: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cartProductItemBottomInput {
  width: 95%;
  height: 70%;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
  color: black;
  border-style: solid;
  border-width: 1px;
  border-color: #dcdcdc;
  border-radius: 10px;
  padding: 5px 5px;
  padding-left: 10px;
  background-color: #fff;
}

.viewPromoPopper {
  display: flex;
  flex-direction: row;
  font-family: "Lato-Bold", sans-serif;
}

.viewListPromo {
  display: flex;
  margin-right: 4px;
  color: #17a2b8;
}

.iconInfo {
  max-width: 20px;
  max-height: 20px;
}

.iconInfoInfo {
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.viewBoxTip {
  border-style: solid;
  border-color: red;
  margin-top: 40px;
}

.arrow {
  content: "";
  position: absolute;
  width: 0.75em;
  height: 0.75em;
  top: 0;
  left: 10%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: ivory;
  border: 1px solid #ddd;
  overflow: hidden;
}

.viewTotalHarga {
  display: flex;
  flex-direction: row;
  max-width: 35em;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  font-family: "Lato-Bold", sans-serif;
  position: fixed;
  bottom: 7.5%;
  background-color: rgb(255, 255, 255);
  padding-bottom:5%;
  padding-top: 2%;
  padding-right: 5%;
}

.cartIconClose {
  max-width: 20px;
  max-height: 20px;
}

.viewTotalHargaButton {
  background-color: #529f45;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 40%;
  height: fit-content;
  padding: 10px;
  border-style: solid;
  border-width: 0px;
  border-radius: 8px;
  margin-right: 3%;
}

.viewTotalHargaButtonText {
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
  color: #fff;
  font-size: 14pt;
}

.textDeltomed {
  color: red;
  font-family: "Lato-Bold", sans-serif;
}

.subHeaderComponent {
  position: fixed;
  top: 70px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 35em;
  padding: 22px;
}

.cartProductPadding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  padding-top: 180px;
}
@media (max-width: 480px) {
  .viewTotalHarga {
    min-width: 24em;
  }
}

@media (max-width: 350px) {
  .viewTotalHarga {
    min-width: 20em;
  }
}