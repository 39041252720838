.homeContainer {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  min-height: 100vh;
  padding-top: 50px;
  background-color: #fff;
  /* min-height: 1200px; */
}

.homeContent {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  /* height: 100vh; */
  min-height: 100vh;
  background-color: #fff;
}

.chatContainer {
  /* width: 35em; */
  max-width: 35em;
  /* height: -moz-fit-content; */
  /* height: fit-content; */
  min-height: 100vh;
  padding-top: 9vh;
  background-color: #fff;
}

.waitingRatingContainer {
  /* width: 35em; */
  max-width: 35em;
  /* height: fit-content; */
  height: 100vh;
  /* min-height: 1200px; */
  /* padding-top: 50px; */
  background-color: #fff;
}

.produkDeskripsiContent {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  /* height: 100vh; */
  min-height: 100vh;
  background-color: #fff;
  margin-top: 10px;
}

.productContent {
  max-width: 35em;
  /* width: 35em; */
  /* height: fit-content; */
  /* height: 100vh; */
  min-height: 100vh;
  background-color: #fff;
  margin-top: 25px;
}

.homeBannerPromo {
  padding: 20px;
  border-radius: 20px;
  /* height: 200px; */
  z-index: inherit;
  padding-top: 40px;
}

.homeBannerPromoImage {
  height: 200px;
}

.homeCategory {
  /* margin-top: 20px; */
  padding: 10px;
  border-radius: 20px;
  height: 250px;
}

@media (max-width: 480px) {
  .homeCategory {
    padding: 10px;
    border-radius: 20px;
    height: 205px;
  }
}

.homeCategoryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
  flex: 1;
}

.homeCategoryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* @media (max-width: 780px) {
  .homeCategoryContainer {
    display: flex;
    height: 190px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    overflow-x: scroll;
  }

  .homeCategoryItem {
    display: flex;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
  }
} */

/* @media (max-width: 480px) {
  .homeCategoryContainer {
    display: flex;
    height: 190px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    overflow-x: scroll;
  }

  .homeCategoryItem {
    display: flex;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
  }
} */

.homeCategoryTitle {
  font-family: "Lato-Black", sans-serif;
  font-weight: bold;
  font-size: 12pt;
  text-align: left;
  margin-left: 5px;
}

.homeCategoryCard {
  background-color: #ffffff;
  border-style: solid;
  border-radius: 15px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  /* border-color: black; */
  box-shadow: 0px 0px 0px 2px #ddd;
  /* height: 90px; */
  /* width: 100px; */
}

.homeCategoryImage {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.homeCategoryItemTitle {
  margin-top: 15px;
  /* font-family: "Lato-Bold"; */
  font-family: "Roboto", sans-serif;
  font-size: 1.6vh;
  color: black;
  text-align: center;
}

.homeBanner {
  /* margin-top: 40px; */
  /* padding: 30px; */
  padding-bottom: 25px;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 15px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
}

.homeBannerImage {
  margin: 5px;
  border-radius: 10px;
  /* max-width: 15em; */
  width: 100%;
  height: 100%;
}

hr {
  border-top: 1px solid #bcbc;
}

.homeProduct {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  /* padding-bottom: 12vh; */
  border-radius: 20px;
}

.homeProductRecoment {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 8vh;
  border-radius: 20px;
}

.homeProductTitle {
  font-family: "Lato-Black", sans-serif;
  font-weight: bold;
  font-size: 12pt;
  text-align: left;
  margin-left: 5px;
}

.homeProductContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-top: 20px;
  margin-right: -15px;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 50px;
  /* overflow: hidden; */
}

.homeProductContainer::-webkit-scrollbar {
  display: none;
}

.homeProductContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.homeProductRecomenContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  /* max-width: 35em; */
}

.homeProductRecomenItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  height: fit-content;
  width: 30%;
  min-width: 150px;
  margin: 2px 8px 15px 8px;
  padding-bottom: 25px;
}

.homeProductItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: #f8f8f8;
  box-shadow: 0px 0px 0px 2px #ddd;
  height: fit-content;
  width: 32%;
  min-width: 150px;
  margin: 2px 8px 15px 8px;
  padding-bottom: 25px;
}

.homeProductImage {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  margin: 5px;
  margin-top: 25px;
  height: 130px;
}

.homeProductItemTitle {
  margin: 5px 10px;
  font-family: "Lato-Regular", sans-serif;
  text-align: left;
  font-size: 2vh;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.homeProductItemPrice {
  font-family: "Lato-Regular", sans-serif;
  color: #529f45;
  display: flex;
  font-size: 2.4vh;
  margin: 0px 10px;
}

.homeProductItemView {
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.homeProductItemRating {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homeProductItemRatingValue {
  font-family: "Lato-Bold", sans-serif;
  margin-left: 5%;
  text-align: left;
}

.homeProductItemButton {
  margin: auto;
  margin-top: 5px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #529f45;
  border-radius: 10px;
  width: 80%;
  height: 30px;
}

.homeProductItemButtonBeli {
  text-align: center;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
  font-size: 12pt;
}

.homeProductItemPromo {
  display: flex;
  position: absolute;
  margin: 7px;
  padding: 4px 10px;
  background-color: #b4e5ed;
  justify-content: center;
  align-items: center;
}

.homeProductItemPromoText {
  font-family: "Lato-Bold", sans-serif;
  color: #17a2b8;
  font-size: 12pt;
  font-weight: bold;
}

.homeViewLihatSemua {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.homeLihatSemua {
  font-family: "Lato-Black", sans-serif;
  font-weight: bold;
  font-size: 10pt;
  text-align: left;
  color: #529f45;
  margin-right: 4px;
}

.homeImageIconNextAtas {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.homeGroupLihatSemua {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.halfView {
  display: flex;
}

.isiHalf {
  margin-left: 5px;
  color: #17a2b8;
  /* text-transform: lowercase; */
  font-family: "Lato-Bold", sans-serif;
}

.styles-module_whatsappButton {
  align-items: center;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  position: fixed;
  right: 1rem;
  -webkit-user-select: none;
  user-select: none;
  bottom: 5rem;
}

.styles-module_whatsappButton svg {
  fill: #fff;
  height: 55px;
  padding: 0.5rem;
  width: 55px;
}

.styles-module_whatsappButton:after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: styles-module__pulse__xf4Ba;
  animation-name: styles-module__pulse__xf4Ba;
  -webkit-animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  border-color: inherit;
  border-radius: 50%;
  content: "";
  height: 60px;
  opacity: 0;
  position: absolute;
  width: 60px;
}

.homeDashboardBackground {
  padding: 20px;
  height: 200px;
  z-index: inherit;
  margin-top: 3%;
  padding-top: 40px;
  background-color: #529F45;
}

.homeContentDashboard {
  padding: 20px;
  height: 200px;
  width: 32em;
  z-index: inherit;
  background-color: #FFF;
  border-radius: 14px;
  position: absolute;
  top: 19vh;
  box-shadow: 0px 0px 0px 2px #ddd;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.homeCategoryContainerDistributor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15vh;
  gap: 10px;
  flex: 1 1;
  padding-left: 20px;
  padding-bottom: 12vh;
}

.homeCategoryItemContainerDistributor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #F6F6F6;
  border-radius: 5px;
  width: 97%;
  margin-bottom: 1%;
}

.homeCategoryImageContainerDistributor {
  padding: 10px;
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

.homeCategoryImageContainerDistributor img {
  width: 100%;
  height: 100%;
}

.homeDashboardTitle {
  font-family: "Lato-Medium", sans-serif;
  /* font-weight: bold; */
  font-size: 15pt;
  text-align: left;
  margin-left: 5px;
  color: #FFF;
}

.homeDashboardName {
  font-family: "Lato-Medium", sans-serif;
  font-weight: bold;
  font-size: 17pt;
  text-align: left;
  margin-left: 5px;
  color: #FFF;
  margin-top: 5px;
}

.homeDashboardTextContent {
  font-family: "Lato-Regular", sans-serif;
  font-size: 10pt;
  text-align: left;
  color: #529F45;
  font-weight: 700;
}

.homeDashboardContainerActivity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin-bottom: 10px;
}

.homeDashboardContainerActivityNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeCategoryContainerTextItem {
  margin-left: 10px;
}

.homeDashboardContainerLastActivity {
  display: flex;
  align-self: flex-start;
}

@media (max-width: 480px) {
  .homeContentDashboard {
    width: 90%;
  }
}
.homeDashboardTitleContent {
  font-family: "Lato-Regular", sans-serif;
  font-size: 10pt;
  text-align: left;
  color: #000000;
  font-weight: 500;
}