.promoDetailContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    height: 100vh;
    padding-top: 70px;
    /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .promoDetailContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

/* @media (max-width: 480px) {
    .promoDetailContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

.promoDetailHeader {
    flex: 1;
    width: 100%;
   /* height: fit-content; */
    /* height: 100vh; */
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
}

.promoDetailHeaderTitle {
    display: flex;
    align-items:center; 
    padding: 18px;
    border-bottom: 5px solid #D4D4D4;
    margin-bottom: 15px;
    font-family: 'Lato-Bold', sans-serif;
}

.promoDetailBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.promoDetailBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.promoDetailHeaderMiddle {
    margin-left: 4%;
}

.promoDetail {
    margin-top: 15px;
}

.promoDetailCardContainer {
    padding: 15px;
    width: 92%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-top: 2%;
    margin-left: 4%;
    margin-right: 4%;
}

.promoDetailCardContainer .date {
    margin-bottom: 10px;
    font-family: 'Lato-Bold', sans-serif;
}

.promoDetailCardContainer .first {
    padding-right: 100px;
    font-family: 'Lato-Bold', sans-serif;
}

.promoDetailCardContainer .two {
    font-family: 'Lato-Bold', sans-serif;
}

.promoDetailBanner {
    padding: 4%;
}

.promoDetailBannerImage {
    height: 200px;
    width: 98%;
    border-radius: 10px;
}

.promoDetailButton {
    width: 92%;
    height: 50px;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
    margin-top: 25%;
    margin-left: 4%;
}

.promoDetailButtonText {
    color: #fff;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 20px;
}

.promoDetailReward {
    margin-top: 15px;
    padding-bottom: 13vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}