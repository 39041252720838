.navbar {
  flex: 1;
  width: 100%;
  height: 70px;
  max-width: 35em;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 5px solid #bcbc;
  z-index: 1090;
}

.navbarIcon {
  width: 40px;
  height: 40px;
}

.navbar p {
  font-family: "Lato-Bold", sans-serif;
  font-size: 2vh;
}

.containerNavbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
