.diskusiProdukDistributorScroll {
    overflow-x: auto;
    padding-bottom: 10%;
}

.diskusiProdukDistributorScroll::-webkit-scrollbar {
    display: none;
}

.diskusiProdukDistributorScroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.chatNavbarDiskusi {
    flex: 1 1;
    width: 100%;
    height: 70px;
    max-width: 35em;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    padding: 5px 10px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1090;
}

.chatNavbarInputDiskusi {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #bcbc;
    border-radius: 10px;
    width: 90%;
    height: 60%;
    padding: 0px 5%;
    margin-right: 10px;
}