.paymentTopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  max-width: 35em;
  margin: 10px 5%;
}

.paymentTopButtonContainer {
  width: 90%;
  /* height: fit-content; */
  /* height: 100vh; */
  padding: 10px 0px;
  margin-top: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #bcbc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.paymentTopButtonIcon {
  height: 50px;
}

.paymentTopButtonText {
  margin-left: 2%;
  font-family: "Lato-Bold", sans-serif;
  color: #000000;
  font-size: 15pt;
  font-weight: 500;
}

.paymentTopTitle {
  font-family: "Lato-Bold", sans-serif;
  color: #000000;
  font-size: 15pt;
  font-weight: 500;
}

.paymentBottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  max-width: 35em;
  margin: 10px 5%;
  padding: 20px 0px;
}

.paymentBottomTitle {
  text-align: left;
  font-family: "Lato-Bold", sans-serif;
  color: #000000;
  font-size: 15pt;
  font-weight: 500;
}
.payment-method-text-shop {
  width: 100%;
  margin-bottom: 10px;
  padding: 0 20px 0 20px;
  text-align: left;
  font-family: "Lato-Bold", sans-serif;
  color: #000000;
  font-size: 13pt;
  font-weight: 500;
}
.payment-method-text-shop-partner {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  font-family: "Lato-Bold", sans-serif;
  color: #000000;
  font-size: 13pt;
  font-weight: 500;
}
.payment-method-div-partner {
  width: 100%;
  margin-bottom: 10px;
  padding: 0 0 0 20px;
}
.paymentBottomContainer hr {
  width: 95%;
  margin: 10px 0px;
}

.paymentBottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  /* height: fit-content; */
  /* height: 100vh; */
  margin: 3px 0px;
}
.paymentBottomRowPartner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    /* height: fit-content; */
    /* height: 100vh; */
    margin: 3px 0px;
}

.paymentBottomRowLeft {
  font-family: "Lato-Bold", sans-serif;
  text-align: left;
  color: #7d7d7d;
  font-size: 14pt;
}

.paymentBottomRowRight {
  font-family: "Lato-Bold", sans-serif;
  text-align: right;
  color: #529f45;
  font-size: 14pt;
}

.paymentBottomPay {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
  width: 90%;
}

.paymentBottomPayLeft {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  width: 50%;
}

.paymentBottomPayRight {
  display: flex;
  justify-content: right;
  text-align: right;
  width: 50%;
}

.paymentBottomPayLeftTitle {
  font-family: "Lato-Bold", sans-serif;
  color: black;
  font-size: 16pt;
}

.paymentBottomPayLeftPrice {
  font-family: "Lato-Bold", sans-serif;
  color: #529f45;
  font-size: 16pt;
}

.paymentBottomPayButton {
  background-color: #529f45;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  /* height: fit-content; */
  /* height: 100vh; */
  padding: 10px;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
}

.paymentBottomPayButtonIcon {
  height: 25px;
}

.paymentBottomPayButtonText {
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
  color: #fff;
  font-size: 16pt;
  margin-left: 5%;
}

.buttonSwitch {
  display: flex;
  border-width: 2px;
  border-color: #ddd;
  width: 90%;
  justify-content: space-between;
  border-style: solid;
  border-radius: 3px;
  align-items: center;
  padding-left: 10px;
}
