.produkListContainer {
  max-width: 35em;
  /* width: 35em; */
  /* height: 1000px; */
  /* height: fit-content; */
  min-height: 100vh;
  /* min-height: 1200px; */
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 80px;
}

.produkListContainer .header1 {
  width: 100%;
  height: fit-content;
  max-width: 35em;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  align-items: center;
  z-index: 1500;
}

.produkListContainer .header1 .headerContainer {
  border-bottom: 5px solid #bcbc;
  display: flex;
  align-items: center;
}

.produkListContainer .header1 .headerFilter {
  margin-top: 23%;
  text-align: left;
  padding: 2%;
  margin-left: 2%;
  overflow-y: scroll;
  display: flex;
}
.produkListContainer .header1 .headerFilter::-webkit-scrollbar {
  display: none;
}
.produkListContainer .header1 .headerFilter {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.produkListContainer .header1 .headerFilter button {
  height: 40px;
  width: 80px;
  background-color: #3f7531;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #d3d3d3;
  display: inline-block;
  flex: 1 0 auto;
}

.produkListContainer .header1 .headerFilter .active {
  height: 40px;
  width: 80px;
  background-color: #529f45;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #d3d3d3;
}
.produkListContainer .header1 .headerFilter .text {
  font-family: 'Lato-Bold', sans-serif;
  color: #fff;
}

.produkListContainer .header1 .headerFilter .active .text {
  font-family: 'Lato-Bold', sans-serif;
  color: #fff;
}

.produkListContainer .header1 .headerFilter button {
  margin-right: 1%;
}

.produkListContainer .headerContainerLogo {
  width: 20%;
}

.produkListContainer .headerContainerLogo .headerLogo {
  width: 60px;
  height: 60px;
}

.produkListContainer .headerContainer .headerSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 50%;
  height: 50px;
  margin-bottom: 1%;
  border-radius: 10px;
  border-width: 3px;
  border-color: #d3d3d3;
  border-style: solid;
}

.produkListContainer .headerSearchLogo {
  width: 20px;
  height: 20px;
}

.produkListContainer .headerTextInput {
  border: 0;
}

input:focus {
  outline: none;
}

.produkListContainer .header1 .headerContainerMenu {
  display: flex;
  /* justify-content: space-evenly;
  width: 30%; */
}

.produkListContainer .headerContainerMenu .headerMenuLogo {
  width: 30px;
  height: 30px;
}
.produkListContainer .content {
  border-radius: 10px;
  border-width: 2px;
  border-color: #F6F6F6;
  background-color: #F6F6F6;
  border-style: solid;
  margin-top: 10px;
  margin-left: 4%;
  margin-right: 4%;
  text-align: left;
}

.produkListContainer .content .product {
  display: flex;
  align-items: center;
}

.produkListContainer .content .product .image {
  object-fit: cover;
  width: 90px;
  height: 90px;
}

.produkListContainer .content .product .desc {
  margin-left: 2%;
}

.produkListContainer .content .product .name {
  font-family: 'Lato-Bold', sans-serif;
}

.produkListContainer .content .product .price {
  font-family: 'Lato-Bold', sans-serif;
  margin-top: 5px;
  color: #529f45;
}