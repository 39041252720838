.identifyContainer {
    max-width: 35em;
    background-color: #fff;
    height: 100vh;
    padding-top: 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

/* @media (max-width: 780px) {
    .identifyContainer {
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
        padding-top: 20%;
    }
} */

/* @media (max-width: 480px) {
    .identifyContainer {
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
        padding-top: 20%;
    }
} */

.identifyJudul {
    font-size: 32px;
    font-weight: 550;
    color: #529f45;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'Lato-Bold', sans-serif;
}

.identifyLogo {
    width: 150px;
    height: 150px;
}

.identifyIcon {
    margin-top: 20px;
    width: 400px;
    height: 250px;
}

.identifyButtonUser {
    margin-top: 30px;
    height: 50px;
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
}

.identifyTextButtonUser {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Lato-Bold', sans-serif;
}