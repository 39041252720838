.tambahProdukDistributorContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
  /* height: fit-content; */
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;
  /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
      .userEditContainer {
          text-align: left;
          max-width: 30em;
          background-color: #fff;
          width: 1000px;
          height: 1000px;
      }
  } */

/* @media (max-width: 480px) {
      .userEditContainer {
          text-align: left;
          max-width: 25em;
          background-color: #fff;
          width: 1000px;
          height: 1000px;
      }
  } */

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
}

.hr {
  height: 5px;
  background-color: #ccc;
  border: none;
}

.tambahProdukDistributorKirim {
  margin-top: 15px;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border-color: #529f45;
  border-style: solid;
}

.tambahProdukDistributorTextKirim {
  color: #529f45;
  font-weight: bold;
  font-size: 15px;
  font-family: "Lato-Bold", sans-serif;
}

.tambahProdukDistributorTextRp {
  margin-top: 6px;
  margin-right: 5px;
  font-size: 15px;
  color: #529f45;
  font-family: "Lato-Bold", sans-serif;
}

.tambahProdukDistributorInfo {
  font-size: 15px;
  color: #17a2b8;
  font-family: "Lato-Medium", sans-serif;
}

.tambahProdukDistributorTextTitle {
  margin-top: 15px;
  font-size: 15px;
  color: black;
  font-family: "Lato-Medium", sans-serif;
}

.tambahProdukDistributorList {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 55px;
}

.tambahProdukDistributorIcon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 29.5vw;
  margin-top: 34.5vh;
  background-color: #fff;
}

.voucherPartnerDistributorButtonTabContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 12px;
}

.voucherPartnerDistributorButtonTab {
  height: 40px;
  width: 49%;
  background-color: #fff;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.voucherPartnerDistributorTextButton {
  font-family: "Lato-Medium", sans-serif;
  color: #000;
}

.voucherPartnerDistributorList {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 10px;
}

.voucherPartnerDistributorIcon1 {
  /* position: absolute; */
  right: 9em;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.voucherPartnerDistributorIcon2 {
  /* position: absolute; */
  right: 7em;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.voucherPartnerDistributorContainerTitle {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.voucherPartnerDistributorImageVoucher {
  height: 90px;
  width: 90px;
  border-radius: 8px;
  object-fit: fill;
}

.voucherPartnerDistributorTextTitle {
  font-family: "Lato-Bold", sans-serif;
  font-size: 20pt;
  font-weight: 500;
  color: black;
}

.voucherPartnerDistributorTable {
  padding: 0px 10px;
}

.voucherPartnerDistributorTableRight {
  text-align: right;
  font-size: 12pt;
  color: #000;
  font-family: "Lato-Regular", sans-serif;
}

.voucherPartnerDistributorTableLeft {
  font-size: 12pt;
  color: #000;
  font-family: "Lato-Medium", sans-serif;
}

@media (max-width: 480px) {
  .voucherPartnerDistributorIcon1 {
    right: 15%;
  }

  .voucherPartnerDistributorIcon2 {
    right: 6%;
  }

  .tambahProdukDistributorIcon {
    right: 15px;
    margin-top: 33.5vh;
  }
}
.containerViewVoucher {
  display: flex;
  flex-direction: row;
  /* background-color: #17a2b8; */
  padding-bottom: 1%;
  align-items: center;
}
.textDateVoucher {
  display: flex;
  flex-direction: row;
  flex: 6;
}
.viewIconVoucher {
  flex: 1;
  /* background-color: #529F45; */
  display: flex;
  flex-direction: row;
}
.textVoucherMinimal {
    display: flex;
    flex-direction: row;
}
.textVoucherMinimal p {
    margin-top: 2%;    
    font-size: 10pt;
}
.btnDetailVoucher {
    display: flex;
    flex: 1;
    justify-content:flex-end;
    align-items: flex-end;
    margin-bottom: 2%;
    margin-right: 2%;
}
.btnDetailVoucher p {
    color: #529f45;

}
.textVoucher {
    margin-top: 5%;
    margin-left: 2%;
}
.textValueVoucher {
    color: #E07126;
}
.textStatusVoucher {
    color: #7D7D7D;
}
.garisVoucher {
  width: 100%;
  height: 1px;
  margin-top: 1%;
  background-color: #C4C4C4;
}