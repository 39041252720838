.wishlistContainer {
    text-align: left;
    max-width: 35em;
    background-color: #fff;
    /* width: 35em; */
   /* height: fit-content; */
    min-height: 100vh;
    padding-bottom: 10px;
    /* padding-top: 6%; */
    /* min-height: 1200px; */
}

.wishlistHeader {
    flex: 1;
    width: 100%;
   height: fit-content;
    max-width: 35em;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    z-index: 1500;
    padding-bottom: 2%;
    padding-top: 19%;
    /* margin-top: 7%; */
}

.wishlistHeaderTitle {
    display: flex;
    align-items:center; 
    padding: 18px;
    border-bottom: 5px solid #D4D4D4;
    margin-bottom: 15px;
}

.wishlistHeader .header .headerTitleContainer .headerTitle {
    font-family: 'Roboto', sans-serif;
}

.wishlistBack {
    width: 13px;
    height: 13px;
    margin-right: 13px;
} 

.wishlistBackText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 17px;
}

.editButtonOK {
    margin-top: 30px;
    height: 40px;
    width: 100%;
    background-color: #529f45;
    border-radius: 10px;
    border: none;
}

.wishlistSearchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 94%;
    border-radius: 10px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 10px;
}

.wishlistSearchLogo {
    width: 20px;
    height: 20px;
}

.wishlistTextInput {
    border: 0;  
    width: 100%;
}

.wishlistSelect {
    width: 100%;
    background-color: #fff;
    margin-bottom: 5px;
    border: 0;
}

/* .wishlist {
    margin-top: 40px;
} */

/* @media only screen and (max-width: 1331px) {
    .wishlist {
        margin-top: 80px;
    }
} */

.wishlistMain {
    border-radius: 7px;
    border-width: 3px;
    border-color: #D3D3D3;
    border-style: solid;
    padding: 15px;
    margin-top: 15px;
    margin-left: 3%;
    margin-right: 3%;
}

.loginTextButtonOK {
    color: #fff;
    font-family: 'Lato-Bold', sans-serif;
}

.wishlistContent {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.wishlistContentImage {
    height: 110px;
    width: 110px;
    margin-right: 10px;
}

.wishlistContentProduct {
    margin-bottom: 15px;
    font-family: 'Lato-Bold', sans-serif;
}

.wishlistContentPrice {
    font-family: 'Lato-Bold', sans-serif;
}

.wishlistFooterButton {
    display: flex;
}

.wishlistButtonOK {
    margin-top: 30px;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border-color: #ff781f;
    border-radius: 10px;
    border-width: 1px;
}

.wishlistTextButtonOK {
    color: #ff781f;
    font-family: 'Lato-Bold', sans-serif;
    /* font-weight: bold; */
    font-size: 15px;
}

.wishlistButtonHapus {
    margin-top: 30px;
    height: 40px;
    width: 7%;
    margin-right: 10px;
    background-color: #fff;
    border-color: #ff781f;
    border-radius: 10px;
    border-width: 1px;
}

@media (max-width: 480px) {
    .wishlistButtonHapus{
        width: 18%;
    }
}

.wishlistTrashIcon {
    height: 25px;
    width: 25px;
}
