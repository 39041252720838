.containerDetailVoucher {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.containerDetailVoucher p {
  font-family: sans-serif;
}
.viewContentDetailVoucher {
  padding-top: 17%;
  padding-left: 5%;
  padding-right: 5%;
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.detailVoucherImage {
  margin-top: 3%;  
  height: 190px;
  width: 100%;
  border-radius: 8px;
  object-fit: fill;

}
.viewTextDetailVoucher {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.textCodeDetailVoucher {
  font-weight: 500;
  font-size: 16pt;
}
.viewTextKiriKanan {
  margin-top: 5%;
  display: flex;
  flex:1;
  flex-direction: row;
}
.textKiriDetailVoucher {
  display: flex;
  flex:1;
}
.textKananDetailVoucher {
  align-self: flex-end;
  text-align: right;
  color: #529F45;
}
.textKiriDeskripsilVoucher {
  margin-top: 5%;
}
.textnilaiDeskripsiVoucher {
  margin-top: 2%;
  color: #7D7D7D;
}
.garisDetailsVoucher {
  width: 100%;
  height: 1px;
  margin-top: 5%;
  background-color: #C4C4C4;
}