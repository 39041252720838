.userEditContainer {
  text-align: left;
  max-width: 35em;
  background-color: #fff;
  /* width: 35em; */
 /* height: fit-content; */
    height: 100vh;
  
  padding-top: 30px;
  /* min-height: 1200px; */
}

/* @media (max-width: 780px) {
    .userEditContainer {
        text-align: left;
        max-width: 30em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

/* @media (max-width: 480px) {
    .userEditContainer {
        text-align: left;
        max-width: 25em;
        background-color: #fff;
        width: 1000px;
        height: 1000px;
    }
} */

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
}

.hr {
  height: 5px;
  background-color: #ccc;
  border: none;
}

.userEditList {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 70px;
}

.subUserEdit {
  text-decoration: inherit;
  /* margin-top: 25px; */
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex; /*CSS3*/
  align-items: center; /*Vertical align*/
}

.userButtonBack {
  display: flex;
  align-items: center; /*Vertical align*/
  border-bottom: 5px solid #bcbc;
  height: 70px;
}

.userBack {
  width: 13px;
  height: 13px;
  margin-right: 13px;
}

.userBackText {
  margin-left: 10px;
  font-weight: bold;
  font-size: 17px;
  font-family: "Lato-Bold", sans-serif;
}

.userEditTextTitle {
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
  color: black;
  font-family: "Lato-Bold", sans-serif;
}

.userEditInput {
  margin-top: 10px;
  margin-bottom: 2px;
  border: 0;
  font-size: 14px;
  font-weight: 200;
  color: black;
  font-family: "Lato-Bold", sans-serif;
}

.editButtonEditOK {
  margin-top: 15px;
  height: 40px;
  width: 100%;
  background-color: #529f45;
  border-radius: 10px;
  border: none;
}

.editTextButtonOK {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  font-family: "Lato-Bold", sans-serif;
}

.QrContent {
  display: flex;
  width: 100%;
  height: 70%;
  margin-top: 10%;
  margin-bottom: 10%;
  align-items: center;
  justify-content: center;
}

.resendOtp {
  margin-top: 20px;
  font-family: "Lato-Bold", sans-serif;
  font-size: 10px;
}
