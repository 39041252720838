.container-list-mission {
    flex: 1;
    width: 100%;
    /* height: fit-content; */
    min-height: 100vh;
    max-width: 35em;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    z-index: 1500;
    display: flex;
    font-family: "Lato-Bold", sans-serif;
    flex-direction: column;
}

.content-list-mission {
    width: 100%;
    margin-top: 70px;
    padding-top: 5%;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
}
.card-flexdir-list-mission{
    display: flex;
 flex-direction: row;
}
.btn-card-list-mission {
    display: flex;
    margin-top: 5%;
    margin-right: 1%;
    align-self: flex-end;
    border-style: solid;
    border-color: #FFF;
    color: #FFF;
    padding: 2%;
    border-radius: 5px;

}
.div-card-mission-blue {
    display: flex;
    flex-direction: column;
    /* background-image: linear-gradient(red, yellow); */
    background-image: radial-gradient(circle at left bottom, #0F4D95,#267CE0);
    max-width: 90%;
    width: 90%;
    height: fit-content;
    /* margin-right: 7%;
    margin-left: 7%; */
    padding-left : 3%;
    padding-right : 3%;
    padding-bottom : 10%;
    border-radius: 10px;
    margin-bottom: 5%;
}
.div-card-mission-green {
    display: flex;
    flex-direction: column;
    /* background-image: linear-gradient(red, yellow); */
    background-image: radial-gradient(circle at left bottom, #428733,#6BD05A);
    max-width: 90%;
    width: 90%;
    height: fit-content;
    /* margin-right: 7%;
    margin-left: 7%; */
    padding-left : 3%;
    padding-right : 3%;
    padding-bottom : 10%;
    border-radius: 10px;
    margin-bottom: 5%;
}
.div-card-mission-yellow {
    display: flex;
    flex-direction: column;
    /* background-image: linear-gradient(red, yellow); */
    background-image: radial-gradient(circle at left bottom, #E07126,#E0C226);
    max-width: 90%;
    width: 90%;
    height: fit-content;
    /* margin-right: 7%;
    margin-left: 7%; */
    padding-left : 3%;
    padding-right : 3%;
    padding-bottom : 10%;
    border-radius: 10px;
    margin-bottom: 5%;
}
.card-text-list-mission {
    margin-top: 5%;
    flex: 2;
    display: flex;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    flex-direction: column;
    color: #fff;
    margin-right: 1%;
}
.card-icon-list-mission {
    flex: 1;
    display: flex;
    /* background-color: rgb(201, 39, 39); */
    justify-content: center;
    /* align-items: center; */
}
.icon-list-mission{
    margin-top: 15%;
    height: 90px;
    width: 90px;
}
.card-desk-list-mission {
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
}
.card-reward-list-mission {
    margin-top: 5%;
}
.card-date-list-mission {
    margin-top: 5%;
}
.card-desk-list-mission, .card-reward-list-mission {
    font-size: 20px;
    font-weight: bold;
}
.card-date-list-mission, .btn-card-list-mission{
    display: flex;
    align-items: center;
    font-size: 13px;
}
.icon-available {
    margin-left: 2%;
    margin-right: 3%;
    height: 20px;
    width: 20px;
}
.filter-list-misi {
    display: flex;
    margin-bottom: 5%;
    flex-direction: row;
    align-self: center;
    width: 90%;
}
.filter-list-misi-misi {
    display: flex;
    flex: 1;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 3%;
    color: #a2a2a2
}
.divIconMisiKosong {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.IconMisiKosong {
    height: 50%;
    width: 50%;
    margin-bottom: 3%;
}
