/* .headerArrowContainer {
    width: 10%;
    padding: 5%;
} */

.subscribeDetailContainer {
    background-color: #fff;
    min-height: 100vh;
    max-width: 35em;
    padding-top: 45px;
    padding-bottom: 10vh;
}

.subscribeDetailContent {
    background-color: #fff;
    margin-top: 2%;
    max-width: 35em;
    padding-bottom: 15%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.headerTitleContainer {
    display: flex;
    align-items: center;
    width: 65%;
    height: 70%;
}

.headerIconArrow {
    width: 20px;
    height: 20px;
}

.headerTitle {
    font-family: 'Lato-Bold', sans-serif;
    text-align: left;
    padding-left: 5%;
    font-size: 14pt;
    font-weight: 600;
}

.subscribeDetailImageContainer {
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-left: 5%;
    max-width: 100%;
    height: 500px;
}

.subscribeDetailImage {
    display: flex;
    max-width: 90%;
}

.subscribeDetailChatPosition {
    display: flex;
    align-items: flex-end;
    width: 10%;
    margin-left: 1%;
    margin-bottom: 5%;
}

.subscribeDetailChatButton {
    display: flex;
    justify-content: center;
    background-color: #529F45;
    width: 100%;
    height: 40px;
    border-style: solid;
    border-width: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.subscribeDetailChatImage {
    display: flex;
    align-self: center;
    width: 60%;
}

.subscribeDetailDetailContainer {
    width: 90%;
    margin: auto;
}

.subscribeDetailDetailTitle {
    color: #000000;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14pt;
    font-weight: 600;
    text-align: left;
    margin-top: 10px;
}

.subscribeDetailDetailTextContainer {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.subscribeDetailDetailText {
    text-align: left;
    width: 50%;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13pt;
}

.subscribeDetailDetailTextJumlah {
    text-align: left;
    width: 52%;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13pt;
}

.subscribeDetailDetailData {
    text-align: left;
    width: 40%;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13pt;
}

.subscribeDetailDetailDataKonjungsi {
    width: 10px;
}

.subscribeDetailBottom {
    margin-top: 5%;
    display: flex;
}

.subscribeDetailBottom button {
    margin-right: 2%;
    padding: 3%;
    border-radius: 8px;
    border: none;
}

.subscribeDetailBottom button .text {
    color: #fff;
}

.subscribeDetailBottom .btnUbah {
    background-color: #529F45;
    font-family: 'Lato-Bold', sans-serif;
}

.subscribeDetailBottom .btnChange {
    background-color: #ff781f; 
    font-family: 'Lato-Bold', sans-serif;
    width: 120pt;
}
